import React, { useCallback, useState } from 'react';

import { StyledButton, StyledText, StyledToggleContainer } from './styles';
import type { ToggleOptions } from './types';

export default function useToggleButtons<T extends string>({
  onChange,
  options = [],
}: ToggleOptions<T>) {
  const [value, setValue] = useState(
    options.length > 0 ? options[0].value : undefined
  );

  const render = useCallback(
    () => (
      <StyledToggleContainer>
        {options.map((option) => (
          <StyledButton
            key={option.value}
            selected={value === option.value}
            onClick={() => {
              setValue(option.value);
              onChange?.(option.value);
            }}
          >
            <StyledText selected={value === option.value} tag="span">
              {option.label || option.value}
            </StyledText>
          </StyledButton>
        ))}
      </StyledToggleContainer>
    ),
    [onChange, options, value]
  );

  return { render, value };
}
