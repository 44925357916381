import { useMemo } from 'react';

import { stringUtil } from '@goodfynd/react-web.lib.utils';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type {
  VendorProductOptionsUpdateResponse,
  VendorProductOptionsV2Response,
} from '../../services/api/types';
import type { AddUpdateProductOptionV2Request } from '../../stores/product-store/types';
import type { OrderingOptionGroup } from '../../types/products';

export const useProductOptionsApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getOptionsByVendorId(
        vendorId: string
      ): Promise<VendorProductOptionsV2Response> {
        try {
          const data: OrderingOptionGroup[] = await api.get(
            endpoints.productOptions.search,
            {
              vendorId,
              includeHidden: true,
            }
          );

          return { success: true, productOptions: data };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async addOption(request: AddUpdateProductOptionV2Request) {
        try {
          const data: OrderingOptionGroup = await api.post(
            endpoints.productOptions.search,
            request
          );

          return { success: true, productOption: data };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async updateOption({ id, ...request }: AddUpdateProductOptionV2Request) {
        try {
          const {
            productOption,
            productOptions,
          }: VendorProductOptionsUpdateResponse = await api.put(
            stringUtil.replaceAll(endpoints.productOptions.base, {
              ':id': id!,
            }),
            request
          );

          return { success: true, productOption, productOptions };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async deleteOption(id: string) {
        try {
          const {
            productOption,
            productOptions,
          }: VendorProductOptionsUpdateResponse = await api.delete(
            stringUtil.replaceAll(endpoints.productOptions.base, { ':id': id! })
          );

          return { success: true, productOption, productOptions };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },
    };
  }, [api]);
};
