import { useCallback, useMemo } from 'react';
import AuthFallback from '@components/auth/AuthFallback';
import { useApp } from '@context/AppContext';
import { SIDE_NAV_WIDTH } from '@goodfynd/react-web.layout.side-nav';
import { FlexView, LoadingTruck } from '@goodfynd/react-web.lib.ui';
import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { useRequireAuth } from '../../../hooks/useRequireAuth';
import { SideNav } from '../SideNav';

import { StyledBody } from './styles';

export default function Layout({ children, fullWidth }: LayoutProps) {
  useRequireAuth();
  const { isLoggedIn, userIsLoading } = useApp();
  const { isReady } = useRouter();

  const loadingComponent = useMemo(
    () => (
      <FlexView align="center" height="100vh" justify="center" size={1}>
        <LoadingTruck />
      </FlexView>
    ),
    []
  );
  const renderPage = useCallback(() => {
    if (userIsLoading || !isReady) {
      return loadingComponent;
    }

    if (!isLoggedIn) {
      return <AuthFallback />;
    }

    return (
      <FlexView wrap="nowrap">
        <SideNav />
        <Box
          component="main"
          sx={{
            height: '100%',
            width: fullWidth
              ? '100%'
              : {
                  sm: `calc(1440px - ${SIDE_NAV_WIDTH}px + 32px)`,
                  xs: '100%',
                },
          }}
        >
          <StyledBody>{children}</StyledBody>
        </Box>
      </FlexView>
    );
  }, [
    children,
    fullWidth,
    isLoggedIn,
    isReady,
    loadingComponent,
    userIsLoading,
  ]);

  return (
    <>
      <Head>
        <meta content="noindex, nofollow" name="robots" />
      </Head>

      <NoSsr>{renderPage()}</NoSsr>
    </>
  );
}
