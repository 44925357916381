import React, { useCallback, useEffect, useState } from 'react';
import type { DehydratedState } from 'react-query';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { ProfileProvider } from '@context/ProfileContext';
import { ResultsProvider } from '@context/ResultsContext';
import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import { GoogleApiProvider } from '@goodfynd/react-web.context.google-api-context';
import { SnackbarProvider } from '@goodfynd/react-web.context.snackbar-context';
import { strings } from '@goodfynd/react-web.lib.strings';
import { DialogProvider } from '@goodfynd/react-web.lib.ui';
import {
  createEmotionCache,
  ThemeProvider,
  useScreenPhone,
} from '@goodfynd/react-web.theme';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Layout } from '../components/layout/Layout';
import config from '../config';
import env from '../config/env';
import routes, { driftRoutes } from '../config/routes';
import { AppProvider } from '../context/AppContext';
import { OrganizationProvider } from '../context/OrganizationContext';
import { VendorProvider } from '../context/VendorContext';
import type { RootStore } from '../stores';
import { DefaultState, useRootStore } from '../stores';
import { RootStoreProvider } from '../stores/root-store/root-store-context';

import '@goodfynd/react-web.design-system/css-vars/themes.css';
import '@goodfynd/react-web.theme/dist/fonts/fonts.css';
import './global.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

Bugsnag.start({
  apiKey: config.env.BUGSNAG_API_KEY,
  enabledReleaseStages: [config.env.NAME.toLowerCase()],
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.env.NAME.toLowerCase(),
});
BugsnagPerformance.start({ apiKey: config.env.BUGSNAG_API_KEY });

import { LayoutDashboard } from '@components/layout/Layout/LayoutDashboard';
import Loading from '@components/Loading';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { ParsedUrlQuery } from 'querystring';
if (config.app.mode.isProduction) {
  console.debug = () => console.info();
  console.error = () => console.info();
  console.log = () => console.info();
  console.warn = () => console.info();
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React
) as BugsnagErrorBoundary;

export type NextPageWithLayout = NextPage & {
  getLayout?: (
    page: React.ReactElement,
    query: ParsedUrlQuery
  ) => React.ReactNode;
};

type AppPropsWithLayout = AppProps<{ dehydratedState: DehydratedState }> & {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
};

function MyApp({
  Component,
  pageProps: { dehydratedState, ...pageProps },
}: AppPropsWithLayout): JSX.Element | null {
  const [emotionCache, setEmotionCache] = useState<EmotionCache>();
  const createRootStore = useRootStore();
  const router = useRouter();

  const [enRoute, toggleRouting] = useState(false);

  useEffect(() => {
    // Client-side cache, shared for the whole session of the user in the browser.
    setEmotionCache(createEmotionCache());
  }, []);

  // Configure/load anything that needs to be ready before site is displayed to user
  // ***NOTE: Not sure if this is the right way to handle for the web
  // ***NOTE RESPONSE Anthony: No this is causing horrible loading times, our SEO is going to be shit. My initial load time before cache kicks in is like 5s */
  useEffect(() => {
    (async () => {
      setRootStore(await createRootStore()); // configure up mobx-state-tree
      // Now wait for state tree to be ready
    })();
  }, [createRootStore]);

  const { drift } = global.window || {};
  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
      }),
    []
  );

  const [rootStore, setRootStore] = useState<RootStore>(DefaultState);

  const isPhone = useScreenPhone();
  const handleRouteChange = useCallback(() => {
    toggleRouting(false);
  }, []);

  const handleRouteChangeStart = useCallback((url: URL) => {
    const nextUrl = new URL(url, window.location.origin);
    if (nextUrl.pathname != window.location.pathname) {
      toggleRouting(true);
    }
  }, []);

  const scrollWatch = useCallback(
    function () {
      if (drift) {
        drift.on('ready', (api: any) => {
          if (
            driftRoutes.find((driftRoute) => {
              if (driftRoute.endsWith('/*')) {
                return location.pathname.includes(driftRoute.replace('/*', ''));
              }
              return driftRoute.includes(location.pathname);
            })
          ) {
            setTimeout(() => {
              api.startInteraction({
                interactionId: config.env.DRIFT_INTERACTION_ID,
              });
            }, 3000);

            document.removeEventListener('scroll', scrollWatch);
          }
        });
      }
    },
    [drift]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [handleRouteChange, handleRouteChangeStart, router.events]);

  useEffect(() => {
    if (isPhone && global.window) {
      document.removeEventListener('scroll', scrollWatch);
    } else {
      document.addEventListener('scroll', scrollWatch);
    }

    return () => {
      document.removeEventListener('scroll', scrollWatch);
    };
  }, [isPhone, scrollWatch]);

  const onRedirectCallback = (appState?: AppState) => {
    router.replace(appState?.returnTo || routes.dashboard.home);
  };

  const renderWithContext = useCallback(
    (children: React.ReactNode) => {
      if (router.asPath.includes('/beta')) {
        return (
          <OrganizationProvider key="beta">{children}</OrganizationProvider>
        );
      }

      return (
        <OrganizationProvider key="legacy">
          <VendorProvider key="legacy">{children}</VendorProvider>
        </OrganizationProvider>
      );
    },
    [router.asPath]
  );

  // Wait for state to be ready
  if (emotionCache && rootStore) {
    const getLayout =
      Component.getLayout ??
      ((page: React.ReactNode) => {
        if (router.asPath.includes('/beta')) {
          return <LayoutDashboard>{page}</LayoutDashboard>;
        }

        return <Layout>{page}</Layout>;
      });
    return (
      <ErrorBoundary>
        <ThemeProvider>
          <Auth0Provider
            clientId={process.env.NEXT_PUBLIC_CLIENT_ID || ''}
            domain={process.env.NEXT_PUBLIC_DOMAIN || ''}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            authorizationParams={{
              scope: 'openid email offline_access',
              audience: process.env.NEXT_PUBLIC_AUDIENCE,
              redirect_uri:
                (typeof window !== 'undefined' &&
                  window.location.origin + routes.account.callback) ||
                '',
            }}
          >
            <RootStoreProvider value={rootStore}>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={dehydratedState}>
                  <CacheProvider value={emotionCache}>
                    <DialogProvider>
                      <SnackbarProvider>
                        <Head>
                          <title>{strings.app.name}</title>
                          <meta
                            content="initial-scale=1, width=device-width"
                            name="viewport"
                          />
                        </Head>

                        <GoogleApiProvider apiKey={env.GOOGLE_API_KEY}>
                          <AppProvider>
                            <ResultsProvider>
                              <ProfileProvider>
                                {enRoute && <Loading type="line" />}
                                {renderWithContext(
                                  getLayout(
                                    <Component {...pageProps} />,
                                    router.query
                                  )
                                )}
                              </ProfileProvider>
                            </ResultsProvider>
                          </AppProvider>
                        </GoogleApiProvider>
                      </SnackbarProvider>
                    </DialogProvider>
                  </CacheProvider>
                </Hydrate>

                {!config.app.mode.isProduction && (
                  <ReactQueryDevtools position="bottom-right" />
                )}
              </QueryClientProvider>
            </RootStoreProvider>
          </Auth0Provider>
        </ThemeProvider>
      </ErrorBoundary>
    );
  }

  return null;
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// export async function getServerSideProps(context) {
//   return {
//     props: {
//       name: 'Lemaire S.',
//     }, // will be passed to the page component as props
//   };
// }

export default MyApp;
