import React from 'react';
import { TextMaskCustom } from '@goodfynd/react-web.lib.forms';
import { FormControl, FormHelperText, InputLabel, NoSsr } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import type { InputPhoneControlProps } from '../types';

export default React.forwardRef<HTMLInputElement, InputPhoneControlProps>(
  function InputPhoneControl({ error, helperText, ...props }, ref) {
    return (
      <NoSsr>
        <FormControl fullWidth>
          {props.label && (
            <InputLabel error={error} htmlFor={props.name}>
              {props.label}
            </InputLabel>
          )}

          <OutlinedInput
            {...props}
            error={error}
            inputComponent={TextMaskCustom}
            inputProps={{ mask: '+1 (999) 999-9999', ...props.inputProps }}
            inputRef={ref}
          />
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </FormControl>
      </NoSsr>
    );
  }
);
