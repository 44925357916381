import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { LotItem } from '../../types/lots';
import type { Suggestion } from '../../types/shared';

import type {
  AddRemoveVendorInterestRequest,
  BookVendorRequest,
  ConfirmRejectLotEventRequest,
} from '../../types/events';
import type { AutocompleteRequest, SearchRequestV2 } from '../../types/search';
import type {
  AutoCompleteResponse,
  EventResponse,
  EventSearchResponse,
  LikeResponse,
  LotEventsResponse,
  VendorLotResponse,
} from '../../services/api/types';
export const useEventApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async addInterest({
        lotId,
        ...request
      }: AddRemoveVendorInterestRequest): Promise<EventResponse> {
        try {
          const data: EventResponse = await api.post(
            stringUtil.replace(endpoints.lots.interest.add, { ':id': lotId }),
            request
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.events.autocomplete,
            { ...request, sector: 'trucks' }
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async bookVendor({
        lotId,
        ...request
      }: BookVendorRequest): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.bookVendor, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async confirm({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.vendorConfirm, { ':id': lotId }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.get(
            stringUtil.replace(endpoints.lots.base, { ':id': id })
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getByLot(lotId: string): Promise<LotEventsResponse> {
        try {
          const data: LotEventsResponse = await api.get(
            stringUtil.replace(endpoints.events.byLot, { ':lotId': lotId })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async search(query: SearchRequestV2): Promise<EventSearchResponse> {
        try {
          const data: EventSearchResponse = await api.get(
            endpoints.events.search,
            omitBy(
              {
                page: 1,
                rows: 24,
                sector: 'trucks',
                ...query,
              },
              isNil
            )
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async toggleLike(id: string, liked: boolean): Promise<LikeResponse> {
        try {
          const data: LikeResponse = await (liked ? api.delete : api.put)(
            stringUtil.replace(endpoints.events.like, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async removeInterest({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.interest.remove, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async reject({
        lotId,
        ...request
      }: ConfirmRejectLotEventRequest): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.post(
            stringUtil.replace(endpoints.lots.vendorRejection, {
              ':id': lotId,
            }),
            request
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async merchants(id: string): Promise<VendorLotResponse> {
        try {
          const data: LotItem = await api.get(
            stringUtil.replace(endpoints.lots.base, { ':id': id })
          );
          return { success: true, entry: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
