import { storageUtil } from '@goodfynd/react-web.lib.utils';
import type { SetStateAction } from 'react';
import { useState } from 'react';

export const useLocalStorageState = <T>(key: string, defaultValue?: T) => {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') {
      return defaultValue;
    }
    return storageUtil.local.get<T>(key, defaultValue);
  });

  const setValue = (value: SetStateAction<T | undefined>) => {
    try {
      const update = value instanceof Function ? value(state) : value;
      setState(update);
      storageUtil.local.set(key, JSON.stringify(update));
    } catch (e) {
      console.error(e);
    }
  };
  return [state, setValue] as const;
};
