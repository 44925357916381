import { useMemo } from 'react';
import {
  ImportCategoryRequest,
  ImportProductGroupRequest,
  ImportProductOptionRequest,
  ImportProductRequest,
} from 'stores/product-store/types';
import { OrderingOptionGroup } from 'types/products';
import { CategoryItem, ProductItem } from 'types/shared';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type {
  CategoryResponse,
  ProductGroup,
  ProductGroupResponse,
  ProductOptionResponse,
  ProductResponse,
  ProductSyncResponse,
} from '../../services/api/types';
export const useCatalogApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async importProduct({
        vendorId,
        organizationProductId,
      }: ImportProductRequest): Promise<ProductResponse> {
        try {
          const data: ProductItem = await api.post(
            stringUtil.replaceAll(endpoints.catalog.productImport, {
              ':vendorId': vendorId,
              ':organizationProductId': organizationProductId,
            })
          );
          return { success: true, product: data };
        } catch (error: any) {
          console.log('error', error);
          return Promise.reject({ success: false, ...error });
        }
      },

      async importProductGroup({
        vendorId,
        organizationProductGroupId,
      }: ImportProductGroupRequest): Promise<ProductGroupResponse> {
        try {
          const data: ProductGroup = await api.post(
            stringUtil.replaceAll(endpoints.catalog.productGroupImport, {
              ':vendorId': vendorId,
              ':organizationProductGroupId': organizationProductGroupId,
            })
          );
          return { success: true, productGroup: data };
        } catch (error: any) {
          console.log('error', error);
          return Promise.reject({ success: false, ...error });
        }
      },

      async importCategory({
        vendorId,
        organizationCategoryId,
      }: ImportCategoryRequest): Promise<CategoryResponse> {
        try {
          const data: CategoryItem = await api.post(
            stringUtil.replaceAll(endpoints.catalog.categoryImport, {
              ':vendorId': vendorId,
              ':organizationCategoryId': organizationCategoryId,
            })
          );
          return { success: true, category: data };
        } catch (error: any) {
          console.log('error', error);
          return Promise.reject({ success: false, ...error });
        }
      },

      async importProductOption({
        vendorId,
        organizationProductOptionId,
      }: ImportProductOptionRequest): Promise<ProductOptionResponse> {
        try {
          const data: OrderingOptionGroup = await api.post(
            stringUtil.replaceAll(endpoints.catalog.productOptionImport, {
              ':vendorId': vendorId,
              ':organizationProductOptionId': organizationProductOptionId,
            })
          );
          return { success: true, productOption: data };
        } catch (error: any) {
          console.log('error', error);
          return Promise.reject({ success: false, ...error });
        }
      },

      async refreshProduct(id: string) {
        try {
          const data: ProductSyncResponse = await api.post(
            stringUtil.replaceAll(endpoints.catalog.productSync, {
              ':id': id,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          console.log('error', error);
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};
