import type { Instance, SnapshotOut } from 'mobx-state-tree';
import { types } from 'mobx-state-tree';

import { EventStore } from '../event-store';
import { withEnvironment } from '../extensions/with-environment';
import { InvoiceStore } from '../invoice-store';
import { OrderStore } from '../order-store';
import { ReportStore } from '../report-store';
import { ScheduleStore } from '../schedule-store';

export { useStores } from './root-store-context';

/**
 * A RootStore model.
 */
export const RootStoreModel = types
  .model('RootStore', {
    invoiceStore: types.optional(InvoiceStore, {}),
    orderStore: types.optional(OrderStore, {}),
    reportStore: types.optional(ReportStore, {}),
    reportStoreInvoices: types.optional(ReportStore, {}),
    reportStoreItems: types.optional(ReportStore, {}),
    reportStoreTransactions: types.optional(ReportStore, {}),
    scheduleStore: types.optional(ScheduleStore, {}),
    eventStore: types.optional(EventStore, {}),
  })
  .extend(withEnvironment);

type RootStoreType = Instance<typeof RootStoreModel>;

/**
 * The RootStore instance.
 */
export type RootStore = RootStoreType;
type RootStoreSnapshotType = SnapshotOut<RootStore>;
export type RootStoreSnapshot = RootStoreSnapshotType;

export const DefaultState = {
  invoiceStore: {},
  orderStore: {},
  scheduleStore: {},
  eventStore: {},
} as RootStore;
