import { useState } from 'react';

export function useAnchorToggle() {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchor);

  const handleClose = () => {
    setAnchor(null);
  };

  const handleOpen = (e: ClickButtonEvent) => {
    setAnchor(e.currentTarget);
  };
  return { open, anchor, handleClose, handleOpen };
}
