import { FormControl } from '@mui/material';

import { styled } from '../../theme/stitches.config';

export const StyledContainer = styled('div', {
  padding: '16px 0',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
});

export const StyledChipContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 8,
  columnGap: 8,
});

export const StyledFormControl = styled(FormControl, {
  width: '100%',
});

export const StyledFormFieldRow = styled('div', {
  alignItems: 'center',
  display: 'flex',
  gap: 8,
});

export const StyledInputSuggestions = styled('div', {
  bottom: 26,
  display: 'flex',
  gap: 8,
  left: 10,
  position: 'absolute',
});

export const StyledModalContent = styled('div', {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
