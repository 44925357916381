import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import {
  ProductGroup,
  ProductGroupDeleteResponse,
  ProductGroupListResponse,
  ProductGroupResponse,
  ProductGroupToggleResponse,
  ProductGroupUpdateResponse,
} from '../../services/api/types';
import useFetch from '../../services/api/useFetch';
import { AddUpdateProductGroupRequest } from '../../stores/product-store/types';

export const useProductGroupApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<ProductGroupResponse> {
        try {
          const data: ProductGroup = await api.get(
            stringUtil.replace(endpoints.productGroups.base, { ':id': id })
          );
          return { success: true, productGroup: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async list(vendorId: string): Promise<ProductGroupListResponse> {
        try {
          const data: ProductGroup[] = await api.get(
            endpoints.productGroups.search,
            {
              vendorId,
              includeHidden: true,
            }
          );
          return { success: true, productGroups: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async add(
        request: AddUpdateProductGroupRequest
      ): Promise<ProductGroupUpdateResponse> {
        try {
          const data: ProductGroupUpdateResponse = await api.post(
            endpoints.productGroups.search,
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async update(
        request: AddUpdateProductGroupRequest
      ): Promise<ProductGroupUpdateResponse> {
        try {
          const data: ProductGroupUpdateResponse = await api.put(
            stringUtil.replace(endpoints.productGroups.base, {
              ':id': request.id!,
            }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async delete(id: string): Promise<ProductGroupDeleteResponse> {
        try {
          const data: ProductGroupDeleteResponse = await api.delete(
            stringUtil.replace(endpoints.productGroups.base, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async patchDefaultMenu(
        vendorId: string,
        id: string
      ): Promise<ProductGroupResponse> {
        try {
          const data: ProductGroupResponse = await api.patch(
            stringUtil.replaceAll(endpoints.productGroups.setDefault, {
              ':id': id,
              ':vendorId': vendorId,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async toggle(
        id: string,
        visible: boolean
      ): Promise<ProductGroupToggleResponse> {
        try {
          const data: ProductGroupToggleResponse = await api.patch(
            stringUtil.replaceAll(endpoints.productGroups.toggle, {
              ':id': id,
              ':visible': String(visible),
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
      async export(menuId: string) {
        try {
          const data: ResponseBase = await api.post(
            stringUtil.replaceAll(endpoints.productGroups.organization.export, {
              ':id': menuId,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
