import { stringUtil } from '@goodfynd/react-web.utils.string-util';
import { stringifyUrl } from 'query-string';

import routes from '../config/routes';

export const getDashboardRoutePath = (
  pathTemplate: string,
  entityId = 'profile'
): string => {
  return stringUtil.replace(pathTemplate, {
    ':id': entityId,
  });
};

export const getOrganizationRoutePath = (
  pathTemplate: string,
  orgNameId: string
) => {
  return stringUtil.replace(pathTemplate, {
    ':orgId': orgNameId,
    ':organizationId': orgNameId,
  });
};

export const getEventDetailRoutePath = (
  lotId = '',
  vendorId?: string
): string => {
  return stringifyUrl({
    url: stringUtil.replace(routes.events.details, {
      ':id': lotId,
    }),
    query: {
      vendorId,
    },
  });
};
