import React, { useCallback, useEffect, useState } from 'react';
import Text from '@goodfynd/react-web.typography.text';
import { FormControl, FormControlLabel, FormLabel } from '@mui/material';

import type { InputCheckboxListProps } from '../types';

import { StyledCheckbox, StyledLabel } from './styles';

export default function InputCheckboxListControl<T = string>({
  defaultValue,
  label,
  max = 0,
  name,
  options,
  setValue,
}: Omit<InputCheckboxListProps<T>, 'control'>) {
  const [selectedOptions, setSelectedOptions] = useState<InputOption[]>(
    options.filter((option) => defaultValue?.includes(option.value))
  );
  const [touched, setTouched] = useState(false);

  const handleSelect = useCallback(
    (selectedOption: InputOption) => {
      setTouched(true);
      const alreadySelected = selectedOptions.find(
        (option) => option.value === selectedOption.value
      );

      if (alreadySelected) {
        const remaining = selectedOptions.filter(
          (item: InputOption) => item.value !== selectedOption.value
        );
        setSelectedOptions(remaining);
      } else if (max === 0 || selectedOptions.length !== max) {
        setSelectedOptions((prevOptions: InputOption[]) => [
          ...prevOptions,
          selectedOption,
        ]);
      } else {
        //TODO: Show max reached message in snackbar?
      }
    },
    [max, selectedOptions]
  );

  useEffect(() => {
    if (touched) {
      setValue?.(name, selectedOptions);
    }
  }, [name, selectedOptions, setValue, touched]);

  return (
    <FormControl fullWidth variant="outlined">
      {label &&
        (typeof label === 'string' ? (
          <FormLabel component="legend">{label}</FormLabel>
        ) : (
          label
        ))}

      {options.map((option: any) => {
        return (
          <FormControlLabel
            key={option.value}
            control={
              <StyledCheckbox
                onChange={() => handleSelect(option)}
                checked={
                  !!selectedOptions.find(
                    (selectedOption) => option.value === selectedOption.value
                  )
                }
              />
            }
            label={
              <StyledLabel>
                <Text>{option.label}</Text>
                <Text>{option.labelRight}</Text>
              </StyledLabel>
            }
          />
        );
      })}
    </FormControl>
  );
}
