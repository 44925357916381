import { styled } from '@goodfynd/react-web.theme';
import Checkbox from '@mui/material/Checkbox';

export const StyledLabel = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledCheckbox = styled(Checkbox, {
  '&.MuiCheckbox-root': {
    width: 32,
    height: 18,
  },
});
