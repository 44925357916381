import React, { useCallback } from 'react';
import { StyledFlexBetweenH } from '@goodfynd/react-web.styles';
import { useTheme } from '@goodfynd/react-web.theme';
import { getThemeColor } from 'utils/base';

import { StyledAxisLabel, StyledContainer, StyledTrend } from './styles';
import type { TrendGraphProps } from './types';

export default function TrendGraph({
  animate = true,
  animationDuration = 1300,
  data,
  lineCap = 'round',
  lineColors = ['GreenBrand'],
  lineRadius = 8,
  lineWidth = 4,
  xAxisLabelLeft,
  xAxisLabelRight,
  ...props
}: TrendGraphProps) {
  const { isDarkMode } = useTheme();

  const getData = useCallback(() => {
    if (isNaN(data[0])) {
      return [0, 0];
    }

    if (isNaN(data[1])) {
      return [data[0], 0];
    }

    return data;
  }, [data]);

  return (
    <StyledContainer>
      <StyledTrend
        {...props}
        autoDraw={animate}
        autoDrawDuration={animationDuration}
        autoDrawEasing="ease-out"
        data={getData()}
        radius={lineRadius}
        smooth
        strokeLinecap={lineCap}
        strokeWidth={lineWidth}
        gradient={lineColors.map((colorName) =>
          getThemeColor(colorName, isDarkMode)
        )}
      />
      <StyledFlexBetweenH>
        <StyledAxisLabel>{xAxisLabelLeft}</StyledAxisLabel>
        <StyledAxisLabel>{xAxisLabelRight}</StyledAxisLabel>
      </StyledFlexBetweenH>
    </StyledContainer>
  );
}
