import find from 'lodash/find';

import config from '../config';
import strings from '../config/strings';
import { PickupTimeOption } from '../types/vendors';
import * as dateUtil from './date-util';
import * as stringUtil from './string-util';

import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type { ScheduleItem } from '../types/search';
import type { Agreement, VendorItem } from '../types/shared';
export const getExistingSchedule = (
  selectedDates: Date[],
  vendor: VendorItem,
  date?: string
): OperatingDayLocation[] =>
  vendor.map?.schedule.dayLocations
    .filter((day) => {
      return selectedDates.find(
        (selectedDate: Date) =>
          day.start ===
          (date ||
            dateUtil.format(selectedDate, config.dateFormats.yearMonthDay))
      );
    })
    .map((day) => ({
      ...day,
      start: new Date(day.start),
      end: new Date(day.end),
    })) ?? [];

export const getExistingScheduleDictionary = (
  dayLocations: OperatingDayLocation[],
  filterDate?: string
): { [key: string]: OperatingDayLocation[] } => {
  const dictionary: { [key: string]: OperatingDayLocation[] } = {};
  for (let i = 0; i < dayLocations.length; i++) {
    const day = dayLocations[i];
    const date = day.start as string;

    if (filterDate && filterDate !== date) {
      continue;
    }

    if (dictionary.hasOwnProperty(date)) {
      dictionary[date].push({
        ...day,
        start: new Date(day.start),
        end: new Date(day.end),
      });
      continue;
    }

    dictionary[date] = [
      {
        ...day,
        start: new Date(day.start),
        end: new Date(day.end),
      },
    ];
  }

  return dictionary;
};

export const getPayoutOptionLabel = (id?: string) => {
  if (!id) {
    return 'Unknown';
  }

  switch (id) {
    case '1':
      return 'after 2 business days';

    case '2':
      return 'on Friday every week';

    case '3':
      return 'on the last day of every month';

    default:
      return 'according to your settings';
  }
};

export const getPayoutOptionLabelAlt = (id: string) => {
  switch (id) {
    case '1':
      return 'After 2 business days';

    case '2':
      return 'Weekly (every Friday)';

    case '3':
      return 'Monthly (last day of the month)';

    default:
      return 'according to your settings';
  }
};

export const getPickupLocations = (
  item: ScheduleItem
): OperatingDayLocation[] => {
  const operatingDayLocation = item.map?.schedule?.dayLocations[0];
  const compareTime = new Date(operatingDayLocation.end as Date);
  const dateWithoutTime = dateUtil.removeTime(new Date());
  const maxDate = dateUtil.addDays(dateWithoutTime, 7, true);
  const hasCapacity = item.willOpenLater || item.isOpen;
  const pickupLocations = item.map.schedule.dayLocations.filter((day) => {
    const end = day.end as Date;
    const start = day.start as Date;
    return (
      (day.hasLocation &&
        new Date(start as Date) >= dateWithoutTime &&
        !maxDate) ||
      (new Date(start) <= maxDate &&
        ((hasCapacity && new Date(end) >= compareTime) ||
          new Date(start) >= compareTime ||
          new Date(end) >= new Date()))
    );
  });

  console.debug(pickupLocations);
  return pickupLocations;
};

export const getPickupTimes = (
  vendor: VendorItem,
  location: OperatingDayLocation
): PickupTimeOption[] => {
  const pickupCapacity = location.pickupCapacity || vendor.pickupCapacity || 0;
  const takenPickupTimes: { [key: string]: number } = {};
  for (const key in vendor.pickupTimes) {
    const time = vendor.pickupTimes[key];
    const timeKey = new Date(time).toISOString();
    takenPickupTimes[timeKey] = ++takenPickupTimes[timeKey] || 1;
  }

  const currentTime = new Date();
  const timeEnd = new Date(location?.end || dateUtil.endOfDay(currentTime));
  let timeStart = new Date(
    location?.start ||
      dateUtil.addMinutes(currentTime, currentTime.getMinutes() * -1)
  );
  const pickupTimesFound = [];
  let hours = null;
  let optionGroup: PickupTimeOption | undefined = undefined;
  while (pickupCapacity > 0 && timeStart < timeEnd) {
    const timeStartString = timeStart.toISOString();
    const totalTakenTimes = takenPickupTimes[timeStartString] || 0;
    if (
      timeStart > currentTime &&
      (!totalTakenTimes || totalTakenTimes < pickupCapacity)
    ) {
      if (hours !== timeStart.getHours()) {
        if (optionGroup) {
          pickupTimesFound.push(optionGroup);
        }

        hours = timeStart.getHours();
        optionGroup = {
          label: `Starting at ${dateUtil.format(
            timeStart,
            config.dateFormats.hourM
          )}`,
          options: [],
        };
      }

      // const spotsLeft = pickupCapacity - totalTakenTimes;
      optionGroup?.options?.push({
        label: `${dateUtil.format(timeStart, config.dateFormats.time)}`,
        // labelRight: `${spotsLeft} ${spotsLeft > 1 ? 'spots' : 'spot'} left`,
        labelRight:
          totalTakenTimes > 0
            ? `${totalTakenTimes} ${stringUtil.pluralize(
                'spot',
                totalTakenTimes
              )} taken`
            : '',
        value: timeStart.toString(),
      });
    }

    timeStart = dateUtil.addMinutes(timeStart, 15);
  }

  if (optionGroup) {
    pickupTimesFound.push(optionGroup);
  }

  return pickupTimesFound;
};

export const getPreOrderDayOptions = (firstOption?: FilterMenuOption) => {
  const today = dateUtil.startOfDay(new Date());
  const dates: Date[] = [...Array(6)].map((item, index) =>
    dateUtil.addDays(today, index, true)
  );

  const dayOptions: FilterMenuOption[] = dates.map((date) => ({
    label: `${strings.labels.open} ${dateUtil.getDayDisplay(
      date,
      config.dateFormats.weekDayMonthDay
    )}`,
    value: dateUtil.format(date, config.dateFormats.yearMonthDay),
  }));

  if (firstOption) {
    dayOptions.unshift(firstOption);
  }

  return dayOptions;
};

export const idEquals = (
  id: string,
  vendor: {
    id?: string;
    nameId?: string;
  }
) => {
  return !!id && (id === vendor.id || id === vendor.nameId);
};

export const validateSchedule = (
  { end, start }: Interval,
  schedule: OperatingDayLocation[] = []
): boolean => {
  for (let i = 0; i < schedule.length; i++) {
    const existingHours = {
      start: new Date(schedule[i].start),
      end: new Date(schedule[i].end),
    };

    try {
      const hours = { start, end };
      if (!schedule[i].isDeleted && dateUtil.overlaps(hours, existingHours)) {
        return false;
      }
    } catch {
      continue;
    }
  }

  return true;
};

export const getQrCodeLink = (id: string) =>
  `https://qr.goodfynd.com/media/vendor/${id}`;

export const getOfflineAgreement = (agreements: Agreement[]) =>
  find(
    agreements,
    (agreement) => agreement.name === 'Offline Mode Terms & Conditions'
  );

export const getVendorId = (vendorId: string) => {
  return vendorId.split('|')[0];
};
