export const last = (arr: unknown[]) => arr?.slice(-1).pop();

export const groupBy = <T>(
  list: any[],
  criteria: string | ((item: T) => unknown)
) => {
  return list.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {});
};

export const unique = (value: unknown, index: number, self: any) =>
  self.indexOf(value) === index;
