import type { FieldPath, FieldValues } from 'react-hook-form';

export type FieldsErrors<T extends FieldValues = FieldValues> = {
  [key in FieldPath<T>]?: Error;
};

export default class FormErrors<
  T extends FieldValues = FieldValues
> extends Error {
  code?: number;
  formError?: string;
  fieldErrors: FieldsErrors<T>;

  constructor(
    message: string,
    formError?: string,
    fieldErrors: FieldsErrors<T> = {},
    code?: number
  ) {
    super(message);
    this.code = code;
    this.formError = formError;
    this.fieldErrors = fieldErrors;
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, FormErrors.prototype);
  }
}
