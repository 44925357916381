import { useContextVendorId } from '@context/VendorContext/useContextVendorId';
import type { NavLink } from '@goodfynd/react-web.layout.header/nav';
import { usePermissions } from '@hooks/usePermissions';
import { getDashboardRoutePath } from 'utils/routes-util';

import routes from '../../../config/routes';

export function useVendorNavItems(): NavLink[] {
  const vendorId = useContextVendorId();
  const { isVendorManager } = usePermissions();
  return [
    {
      href: vendorId
        ? getDashboardRoutePath(routes.dashboard.overview, vendorId)
        : routes.dashboard.home,
      icon: 'home',
      label: 'Overview',
    },
    {
      href: getDashboardRoutePath(routes.dashboard.products.home, vendorId),
      icon: 'fork_knife',
      label: 'Products',
    },
    {
      id: 'orders',
      href: getDashboardRoutePath(routes.dashboard.orders.home, vendorId),
      icon: 'order',
      label: 'Orders',
    },
    {
      href: getDashboardRoutePath(routes.dashboard.schedule.home, vendorId),
      icon: 'calendar',
      label: 'Schedule',
    },
    {
      href: getDashboardRoutePath(routes.dashboard.events.home, vendorId),
      icon: 'location_pin',
      label: 'Events',
      hide: !isVendorManager,
    },
    {
      href: getDashboardRoutePath(routes.dashboard.invoices.home, vendorId),
      icon: 'invoice',
      label: 'Invoices',
    },
    {
      href: getDashboardRoutePath(routes.dashboard.reports.home, vendorId),
      icon: 'reports',
      label: 'Reports',
      hide: !isVendorManager,
    },
    {
      href: getDashboardRoutePath(routes.dashboard.info.about, vendorId),
      icon: 'info',
      label: 'Business info',
    },
    {
      href: getDashboardRoutePath(routes.dashboard.settings.home, vendorId),
      icon: 'settings',
      label: 'Settings',
      hide: !isVendorManager,
    },
    {
      href: getDashboardRoutePath(routes.support.home, vendorId),
      icon: 'help',
      label: 'Support',
    },
  ];
}
