import { styled } from '@goodfynd/react-web.theme';

export const StyledContainer = styled('aside', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledValue = styled('span', {
  width: 64,
});
