import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import { DietaryTagSearchRepsonse } from '../../services/api/types';
import useFetch from '../../services/api/useFetch';

export const useDietaryApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async search(): Promise<DietaryTagSearchRepsonse> {
        try {
          const data: DietaryTagSearchRepsonse = await api.get(
            endpoints.dietary.search
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};
