import config from '@config/index';
import { IconButton, Logo } from '@goodfynd/react-web.lib.ui';
import { useScreenPhone } from '@goodfynd/react-web.theme';

import { Navigation } from './Navigation';
import { useSidebar } from './SidebarContext';
import {
  StyledLogoContainer,
  StyledSidebarBottom,
  StyledSidebarContainer,
  StyledSidebarContent,
} from './styles';

export const Sidebar = () => {
  const { isCollapsed, toggleSidebar } = useSidebar();
  const isPhone = useScreenPhone();

  return (
    <StyledSidebarContainer collapsed={isCollapsed}>
      {!isPhone && (
        <StyledLogoContainer>
          <Logo
            badgePosition="bottom"
            environment={config.env.NAME_LOWER}
            isCollapsed={isCollapsed}
          />
        </StyledLogoContainer>
      )}

      <StyledSidebarContent>
        <Navigation />
      </StyledSidebarContent>

      <StyledSidebarBottom isCollapsed={isCollapsed}>
        <IconButton
          display="compact"
          icon={isCollapsed ? 'arrow_right' : 'arrow_left'}
          onClick={toggleSidebar}
        />
      </StyledSidebarBottom>
    </StyledSidebarContainer>
  );
};
