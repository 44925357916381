import { useContext } from 'react';

import { ProfileContext, ProfileDispatchContext } from './ProfileContext';

export { default as ProfileProvider } from './ProfileContext';

export function useProfile() {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error(
      'The useProfile hook must be used within a ProfileProvider.'
    );
  }
  return context;
}

export function useProfileDispatch() {
  const context = useContext(ProfileDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useProfileDispatch hook must be used within a ProfileProvider.'
    );
  }
  return context;
}
