import { useOrganization } from '@context/OrganizationContext';
import { Header as FyndHeader } from '@goodfynd/react-web.layout.header';

import config from '../../../config';
import { useApp, useAppDispatch } from '../../../context/AppContext';

import { MAIN_NAV } from './header-links';
import type { HeaderProps } from './types';

export default function Header({
  vendorNameId: profileNameId,
  ...props
}: Partial<HeaderProps>) {
  const { user, isLoggedIn: isAuthenticated } = useApp();
  const { getLoginUrl, logout } = useAppDispatch();
  const { organizationNameId } = useOrganization();

  const navLinks =
    props.navLinks ||
    MAIN_NAV({ user, organizationNameId, profileNameId, isAuthenticated });

  return (
    <FyndHeader
      {...props}
      authenticated_navigation={[]}
      getLoginUrl={getLoginUrl}
      isAuthenticated={isAuthenticated}
      logoEnvironment={config.env.NAME_LOWER}
      logoType="vendors"
      navLinks={navLinks}
      theme="white"
      unauthenticated_navigation={[]}
      userFullName={user.fullName}
      userInitials={user.initials?.replace(' ', '')}
      logout={() => {
        logout();
      }}
    />
  );
}
