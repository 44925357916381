import React from 'react';
import { ProfileSelector } from '@goodfynd/react-web.dashboard.profile-selector';
import { Button, Text } from '@goodfynd/react-web.lib.ui';
import type { SelectOption } from '@goodfynd/react-web.types';
import { useOrganizationApi } from '@hooks/api';
import { useAnchorToggle } from '@hooks/useAnchorToggle';
import Collapse from '@mui/material/Collapse';

import { StyledPopover } from './styles';

type Props = {
  oragnization?: SelectOption<string>;
  recentOptions?: SelectOption<string>[];
  onChange: (option: SelectOption<string>, value?: string) => void;
  varitant?: 'popover' | 'collapse' | 'display';
};
export const OrganizationSwitcher = ({
  oragnization,
  recentOptions = [],
  onChange,
  varitant = 'popover',
}: Props) => {
  const api = useOrganizationApi();
  const { open, anchor, handleClose, handleOpen } = useAnchorToggle();

  const selector = (
    <ProfileSelector
      css={{ padding: 0 }}
      defaultOptions={recentOptions}
      placeholder="Search organizations..."
      selected={oragnization?.value}
      getOptions={async (request) => {
        try {
          const { results } = await api.autocomplete(request);
          return results.map((result) => ({
            image: result.image,
            label: result.uniqueName || result.name,
            value: result.nameId || result.id,
            subLabel: result.description ?? result.nameId,
          }));
        } catch (error) {
          console.error(error);
          return [];
        }
      }}
      onChange={(option) => {
        onChange(option);
        handleClose();
      }}
    />
  );

  if (varitant === 'display') {
    return <Text as="body1">{oragnization?.label}</Text>;
  }

  return (
    <>
      <Button
        color="neutral"
        display="compact"
        expanded={open}
        iconRight="chevron_down_sm"
        iconSize={16}
        justify="space-between"
        onClick={open ? handleClose : handleOpen}
        rank="tertiary"
      >
        <Text>{oragnization?.label ?? 'Select an organization'}</Text>
      </Button>

      {varitant === 'popover' && (
        <StyledPopover
          anchorEl={anchor}
          onClose={handleClose}
          open={open}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Text as="heading5">Switch organization</Text>
          {selector}
        </StyledPopover>
      )}

      {varitant === 'collapse' && <Collapse in={open}>{selector}</Collapse>}
    </>
  );
};
