import routes from '../../config/routes';
import type { UserInfo } from '../../stores/models/user';
import type { UserRole } from '../../types/enums';
import { DashboardRoles } from '../../types/enums';

import type { AccessResult } from './types';

export const userHasAccess = (
  userIsLoading: boolean | undefined,
  isLoggedIn: boolean | undefined,
  user: UserInfo | undefined,
  loginUrl: string,
  allowedRoles: UserRole[] = DashboardRoles
): AccessResult => {
  if (userIsLoading) {
    return {
      hasAccess: undefined,
    };
  }

  if (!isLoggedIn) {
    return {
      hasAccess: false,
      redirectUrl: loginUrl,
    };
  }

  if (
    !user ||
    (allowedRoles.length > 0 &&
      !user.roles?.some((i) => allowedRoles.includes(i)))
  ) {
    return {
      hasAccess: false,
      redirectUrl: routes.accessDenied,
    };
  }

  return {
    hasAccess: true,
  };
};
