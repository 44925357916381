import { useMemo } from 'react';
import { useQuery } from 'react-query';

import config from '../config';
import { useAccountApi } from './api';

import type {
  UserInfoError,
  UserInfoResponse,
} from '../stores/auth-store/types';
import type { UserInfo } from '../stores/models/user';
type UserHookOptions = {
  setUser: (user: UserInfo) => void;
  notFound: () => void;
};

export default function useUser({ setUser, notFound }: UserHookOptions) {
  const api = useAccountApi();

  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    UserInfoResponse,
    UserInfoError
  >(
    [config.queryKeys.auth],
    async () => {
      const data = await api.userInfo();
      return {
        ...data,
        user: {
          ...data.user,
          profiles: data.user.profiles?.sort((a, b) =>
            a.label > b.label ? 1 : -1
          ),
        },
      };
    },
    {
      onError: (err) => {
        switch (err.status) {
          case 404:
            notFound();
          default:
            return;
        }
      },
      onSuccess: (data) => {
        setUser(data.user);
        console.debug('user.profile.updated: ', data.user.email);
      },
    }
  );

  const user = useMemo(() => data?.user, [data?.user]);

  const { profileNames, profileRecords } = useMemo(() => {
    const profileNames: Record<string, string> = {};
    const profileRecords: Record<string, EntityReference> = {};
    const totalPermissions = user?.permissions?.length;
    if (!totalPermissions) {
      return { profileNames, profileRecords };
    }

    for (let i = 0; i < totalPermissions; i++) {
      const permission = user.permissions[i];
      if (!permission.entityName) {
        continue;
      }

      const ref: EntityReference = {
        id: permission.entityId,
        name: permission.entityName,
        nameId: permission.entityNameId,
      };

      if (permission.entityId) {
        profileNames[permission.entityId] = ref.name;
        profileRecords[permission.entityId] = ref;
      }

      if (permission.entityNameId) {
        profileNames[permission.entityId] = ref.name;
        profileRecords[permission.entityNameId] = ref;
      }
    }

    return { profileNames, profileRecords };
  }, [user?.permissions]);

  return {
    isFetched,
    isFetching,
    isLoading,
    isLoggedIn: user?.isAuthenticated || false,
    profileCount: useMemo(
      () => user?.profiles.length || 0,
      [user?.profiles.length]
    ),
    profileNames,
    profileRecords,
    profiles: user?.profiles,
    refetch,
    user,
  };
}
