import type { PropsWithChildren } from 'react';

export function LoadingFullPage({ children }: PropsWithChildren<unknown>) {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );
}
