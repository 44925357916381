import routes from '@config/routes';
import { UserRole } from 'types/enums';
import {
  getDashboardRoutePath,
  getOrganizationRoutePath,
} from 'utils/routes-util';
import { hasRoles } from 'utils/users-util';

import config from '../../../config';

import type { MainNavOptions } from './types';

export const MAIN_NAV = ({
  user: {
    defaultVendorId = '',
    isVendorOwner,
    organizationId: defaultOrgId,
    roles,
  },
  organizationNameId,
  profileNameId,
  isAuthenticated,
}: MainNavOptions) =>
  [
    {
      hide: !isVendorOwner || !isAuthenticated,
      href: config.routes.docs,
      label: 'Help Center',
    },
    {
      hide:
        !isAuthenticated ||
        !hasRoles(roles, [
          UserRole.Admin,
          UserRole.Intern,
          UserRole.VendorOwner,
          UserRole.VendorManager,
          UserRole.VendorEmployee,
          UserRole.SuperAdmin,
          UserRole.OrganizationOwner,
          UserRole.OrganizationManager,
          UserRole.OrganizationAccountant,
        ]),
      href: getDashboardRoutePath(
        routes.dashboard.overview,
        profileNameId || defaultVendorId
      ),
      label: 'Dashboard',
    },
    {
      hide: !isAuthenticated || !hasRoles(roles, [UserRole.Admin]),
      href: getOrganizationRoutePath(
        routes.beta.organization.overview.home,
        organizationNameId || defaultOrgId
      ),
      label: 'Org Dashboard',
    },
    {
      hide: !hasRoles(roles, [
        UserRole.Admin,
        UserRole.Intern,
        UserRole.SuperAdmin,
      ]),
      href: 'https://admins.goodfynd.com',
      label: 'Admins Dashboard',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signIn,
      label: 'Log in',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signUp,
      label: 'Sign up',
    },

    {
      hide: !config.app.mode.isDevelopment,
      href: '/ui',
      label: 'UI',
      links: [
        {
          href: '/ui/buttons',
          label: 'Buttons',
        },
      ],
    },
  ].filter((item) => !item.hide);

export const USER_NAV = ({ user: { roles } }: MainNavOptions) =>
  [
    {
      hide: !hasRoles(roles, [
        UserRole.Admin,
        UserRole.Intern,
        UserRole.SuperAdmin,
      ]),
      href: 'https://admins.goodfynd.com',
      label: 'Admins Dashboard',
    },

    {
      hide: !config.app.mode.isDevelopment,
      href: '/ui',
      label: 'UI',
      links: [
        {
          href: '/ui/buttons',
          label: 'Buttons',
        },
      ],
    },
  ].filter((item) => !item.hide);
