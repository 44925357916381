import { useContext } from 'react';

import { ResultsContext, ResultsDispatchContext } from './ResultsContext';

export { ResultsProvider } from './ResultsContext';

export const useResults = () => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error(
      'The useResults hook must be used within a ResultsProvider'
    );
  }

  return context;
};

export const useResultsDispatch = () => {
  const context = useContext(ResultsDispatchContext);
  if (context === undefined) {
    throw new Error(
      'The useResults hook must be used within a ResultsProvider'
    );
  }

  return context;
};
