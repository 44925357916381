import { styled } from '@goodfynd/react-web.theme';

import Image from '../../Image';

export const StyledBenefitContainer = styled('div', {
  width: `calc(${100 / 3}% - ${64 / 3}px)`,
  '@xs': {
    width: '100%',
  },
});

export const StyledBenefitsContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 32,
  '@xs': {
    gap: 24,
  },
});

export const StyledContainer = styled('section', {
  padding: '48px 0',
  paddingLeft: 24,
  '@xs': {
    padding: '24px 0',
    paddingLeft: 16,
  },
});

export const StyledGraphicContainer = styled('section', {
  alignItems: 'center',
  backgroundColor: '$GreenLightest',
  borderRadius: '50%',
  display: 'flex',
  height: 48,
  justifyContent: 'center',
  marginBottom: 12,
  width: 48,
});

export const StyledImage = styled(Image);
