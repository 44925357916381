import React from 'react';
import { Footer as ReusableFooter } from '@goodfynd/react-web.layout.footer';

import config from '../../../config';

import { FOOTER_NAV, LEGAL_LINKS, SOCIALS } from './footer-links';

export default function Footer() {
  return (
    <ReusableFooter
      drift_interaction_id={config.env.DRIFT_INTERACTION_ID}
      footer_links_1={FOOTER_NAV[0]}
      footer_links_2={FOOTER_NAV[1]}
      footer_links_3={FOOTER_NAV[2]}
      footer_links_4={FOOTER_NAV[3]}
      legal_links={LEGAL_LINKS}
      logo_type="default-white"
      social_links={SOCIALS}
    />
  );
}
