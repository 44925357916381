import { StyledText } from '@goodfynd/react-web.typography.text/dist/styles';

import { styled } from '../../theme/stitches.config';

export const StyledContainer = styled('div', {
  borderRadius: 4,
  padding: 12,
  defaultVariants: { type: 'info' },
  variants: {
    type: {
      error: {
        backgroundColor: '$RedLightest',
        svg: {
          color: '$RedDarker',
        },
        [`${StyledText}`]: {
          color: '$RedDarker !important',
        },
      },
      info: {
        backgroundColor: '$BlueAccent01',
        svg: {
          color: '$BlueAccent04',
        },
        [`${StyledText}`]: {
          color: '$BlueAccent04 !important',
        },
      },
      success: {
        backgroundColor: '$GreenLightest',
        svg: {
          color: '$GreenDarker',
        },
        [`${StyledText}`]: {
          color: '$GreenDarker !important',
        },
      },
      warning: {
        backgroundColor: '$YellowLightest',
        svg: {
          color: '$Neutral13',
        },
        [`${StyledText}`]: {
          color: '$Neutral13 !important',
        },
      },
    },
  },
});
