import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { OrganizationProfile } from '../../types/organizationProfile';
import { VendorProfile } from '../../types/vendorProfile';

export const useProfileApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async organizationProfile(id: string): Promise<OrganizationProfile> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.profiles.organization, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
      async vendorProfile(id: string): Promise<VendorProfile> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.profiles.vendor, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
