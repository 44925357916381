import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type { SearchResults2 } from '../types/search';
import eq from 'lodash/eq';

export const getFacetList = (
  key: string,
  data?: SearchResults2<{}>,
  sortFunc: (a: FilterMenuOption, b: FilterMenuOption) => number = (a, b) =>
    a.label.localeCompare(b.label)
) => {
  return data?.metadata?.facet
    ? (data.metadata.facet[key]?.buckets || [])
        .map((bucket) => {
          const parts = bucket.id.split('|');
          const option: FilterMenuOption = {
            count: bucket.count,
            label: parts?.[1] || bucket.id,
            value: parts?.[0] || bucket.id,
          };
          return option;
        })
        .sort(sortFunc)
    : [];
};

export const toggleFacet = <T>(
  options: T[] = [],
  update: T,
  compare: (a: T, b: T) => boolean = eq
) => {
  if (options.find((option) => option && compare(option, update))) {
    return options.filter((option) => !!option && !compare(option, update));
  } else {
    return [...options, update];
  }
};
