import { DarkColors, DefaultColors } from '@goodfynd/react-web.theme';
import type { ColorName } from '@goodfynd/react-web.ui.icon';

import config from '../config';

import * as storageUtil from './storage-util';

export function addClassNames(classes: Record<string, boolean>): string {
  return Object.keys(classes)
    .filter((key) => classes[key])
    .join(' ');
}

export function asSelectOption(option: { id: string; name: string }) {
  return {
    label: option.name,
    name: option.name,
    value: option.id,
    id: option.id,
  };
}

export function formatDate(t: Date): string {
  return `${padNum(t.getUTCHours())}:${padNum(t.getUTCMinutes())}:${padNum(
    t.getUTCSeconds()
  )}:${padMiliseconds(t.getUTCMilliseconds())}`;
}

export const formatJSON = (obj?: object | null, spacing = 3) => {
  return obj ? JSON.stringify(obj, null, spacing) : undefined;
};

export function getThemeColor(
  colorKey: ColorName,
  isDarkMode: boolean
): string {
  return isDarkMode ? DarkColors[colorKey] : DefaultColors[colorKey];
}

export function getScript(scriptUrl: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.onload = () => resolve(true);
    script.onerror = () =>
      reject(new Error(`failed to load script...${scriptUrl}`));
    document.body.appendChild(script);
  });
}

export const idEquals = (
  id: string,
  entity: {
    id?: string;
    nameId?: string;
  }
) => {
  return !!id && (id === entity.id || id === entity.nameId);
};

export const isIOS =
  typeof navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export function padNum(n: number): string | number {
  return n < 10 ? `0${n}` : n;
}

export function padMiliseconds(n: number): string | number {
  return n < 10 ? `00${n}` : n < 100 ? `0${n}` : n;
}

export function scrollToTop(): void {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function setPageTitle(title: string): string {
  return `${title} | Goodfynd for Vendors`;
}

export function redirectTo(dir: Page): void {
  window.location.href = dir;
}

export function resetAppState(): void {
  storageUtil.local.remove(config.storageKeys.guestOrder);
  storageUtil.local.remove(config.storageKeys.rootState);
  storageUtil.local.remove(config.storageKeys.userLocation);
}

export const urlHistoryReplace = (
  params: Record<string, boolean | Date | null | number | string | undefined>
): void => {
  const url = new URL(window.location.href);
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (!value) {
      url.searchParams.delete(key);
    } else if (value instanceof Date) {
      url.searchParams.set(key, value.toISOString());
    } else {
      url.searchParams.set(key, value.toString());
    }
  });

  window.history.replaceState(null, '', url.toString());
};

export function delay(time: number): Promise<unknown> {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
}

export enum Page {
  Home = '/',
  Search = '/search',
  Profile = '/profile',
  // ... easy page names
}

export function delaySeconds(time: number): Promise<unknown> {
  return new Promise(function (resolve) {
    setTimeout(resolve, 1000 * time);
  });
}
export const errorHasMessage = (
  error: unknown
): error is { message: string } => {
  return !!error && typeof error === 'object' && 'message' in error;
};
