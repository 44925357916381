import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { OrganizationTerritory } from '../../types/shared';
import type {
  DeleteResponse,
  OrganizationTerritoryRequest,
} from '../../services/api/types';
export const useTerritoryApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getTerritories(
        organizationId: string
      ): Promise<OrganizationTerritory[]> {
        try {
          const data: OrganizationTerritory[] = await api.get(
            endpoints.territories.get,
            { organizationId }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async createTerritory(
        request: OrganizationTerritoryRequest
      ): Promise<OrganizationTerritory> {
        try {
          const data: OrganizationTerritory = await api.post(
            endpoints.territories.create,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async updateTerritory(
        territoryId: string,
        request: OrganizationTerritoryRequest
      ): Promise<OrganizationTerritory> {
        try {
          const data: OrganizationTerritory = await api.put(
            stringUtil.replace(endpoints.territories.update, {
              ':id': territoryId,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async deleteTerritory(territoryId: string): Promise<DeleteResponse> {
        try {
          const data: DeleteResponse = await api.delete(
            stringUtil.replace(endpoints.territories.delete, {
              ':id': territoryId,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },
    };
  }, [api]);
};
