import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import type {
  OrganizationSubscription,
  OrganizationSubscriptionResponse,
} from '../../../services/api/types';
import useFetch from '../../../services/api/useFetch';
import { organizationSubscriptionEndpoints } from './endpoints';
import type {
  ConfigurationResponse,
  EmbeddableMapsConfiguration,
} from './types';

export const useOrganizationSubscriptionApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getEmbeddableMapsConfiguration(
        organizationId: string
      ): Promise<ConfigurationResponse<EmbeddableMapsConfiguration>> {
        try {
          const data: EmbeddableMapsConfiguration = await api.get(
            stringUtil.replaceAll(
              organizationSubscriptionEndpoints.getEmbeddableMapsConfiguration,
              {
                ':organizationId': organizationId,
              }
            )
          );
          return { success: true, configuration: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getHubSpotOrganizationSubscription(
        portalId: string
      ): Promise<OrganizationSubscriptionResponse> {
        try {
          const data: OrganizationSubscription = await api.get(
            stringUtil.replaceAll(
              organizationSubscriptionEndpoints.getHubSpotOrganizationSubscription,
              {
                ':portalId': portalId,
              }
            )
          );
          return { success: true, organizationSubscription: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};
