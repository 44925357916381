import React, { createContext, useState } from 'react';

import type {
  ResultsContextDispatchValue,
  ResultsContextState,
  ResultsContextValue,
  ResultsProviderProps,
} from './types';

export const ResultsContext = createContext<ResultsContextValue | undefined>(
  undefined
);

export const ResultsDispatchContext = createContext<
  ResultsContextDispatchValue | undefined
>(undefined);

export const ResultsProvider = ({ children }: ResultsProviderProps) => {
  const [state, setResults] = useState<ResultsContextState>({
    newSchedules: [],
  });

  return (
    <ResultsContext.Provider value={state}>
      <ResultsDispatchContext.Provider
        value={{
          addNewSchedules: (newSchedules) => {
            setResults((prevState) => ({
              ...prevState,
              newSchedules: [
                ...newSchedules.map((newSchedule) => ({
                  ...newSchedule,
                  isNew: true,
                })),
                ...prevState.newSchedules,
              ],
            }));
          },
          setResults,
        }}
      >
        {children}
      </ResultsDispatchContext.Provider>
    </ResultsContext.Provider>
  );
};
