import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

export const InvoiceStore = types
  .model('InvoiceStore', {
    blockedInvoices: types.optional(types.array(types.string), []),
    busy: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
  }))
  .actions((self) => ({
    unblock: (id: string) => {
      self.blockedInvoices = cast(
        self.blockedInvoices.filter((orderId) => orderId !== id)
      );
    },
  }));

type InvoiceStoreType = Instance<typeof InvoiceStore>;
export type InvoiceStore = InvoiceStoreType;
type InvoiceStoreSnapshotType = SnapshotOut<typeof InvoiceStore>;
export type InvoiceStoreSnapshot = InvoiceStoreSnapshotType;
