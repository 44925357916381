import React from 'react';
import { Text } from '@goodfynd/react-web.typography.text';
import type { IconName } from '@goodfynd/react-web.ui.icon';
import { Icon } from '@goodfynd/react-web.ui.icon';

import { StyledContainer } from './styles';
import type { AlertProps } from './types';

export default function Alert({
  children,
  css,
  showIcon = true,
  type = 'info',
  ...props
}: AlertProps) {
  const getIcon = (): IconName => {
    switch (type) {
      case 'error':
        return 'caution';

      case 'info':
        return 'info';

      case 'success':
        return 'check';

      case 'warning':
        return 'caution_alt';

      default:
        return 'chevron_right';
    }
  };

  return (
    <StyledContainer {...props} css={css} type={type}>
      {showIcon ? (
        <Icon {...props} label={children} name={props.icon || getIcon()} />
      ) : typeof children === 'string' ||
        (Array.isArray(children) && typeof children[0] === 'string') ? (
        <Text inherit type="body2">
          {children}
        </Text>
      ) : (
        children
      )}
    </StyledContainer>
  );
}
