import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import { queryUtil } from '@goodfynd/react-web.utils.query-util';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { SearchRequest } from '../../types/search';

import type {
  CancelEventRequest,
  EventRequest,
  EventSyncRequest,
  ScheduleItem,
  ScheduleSearchRequest,
} from '../../types/schedule';
import type {
  CountResponse,
  EventResponse,
  ScheduleAddResponse,
  ScheduleResponse,
  ScheduleSearchResponse,
  ScheduleWalkup,
  VendorResponse,
  WalkupUpdateResponse,
} from '../../services/api/types';
export const useScheduleApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async addEvents(
        vendorId: string,
        data: EventSyncRequest
      ): Promise<ScheduleAddResponse> {
        try {
          const response: ScheduleAddResponse = await api.post(
            endpoints.schedule.add,
            { ...data, vendorId }
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async cancel({
        id,
        ...request
      }: CancelEventRequest): Promise<EventResponse> {
        try {
          const response: EventResponse = await api.post(
            stringUtil.replace(endpoints.schedule.cancel, {
              ':id': id,
            }),
            request
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async count(query: ScheduleSearchRequest): Promise<CountResponse> {
        try {
          const response: CountResponse = await api.get(
            endpoints.schedule.count,
            query
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<ScheduleItem> {
        try {
          const response: ScheduleItem = await api.get(
            stringUtil.replace(endpoints.schedule.base, {
              ':id': id,
            })
          );
          return response;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async search(
        query: ScheduleSearchRequest
      ): Promise<ScheduleSearchResponse> {
        try {
          const response: ScheduleSearchResponse = await api.get(
            endpoints.schedule.search,
            {
              page: 1,
              rows: 48,
              ...query,
            } as SearchRequest
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async sendMarketing(id: string): Promise<EventResponse> {
        try {
          const response: EventResponse = await api.post(
            stringUtil.replace(endpoints.vendors.sendMarketing, {
              ':id': id,
            })
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async update(
        eventId: string,
        data: EventRequest
      ): Promise<EventResponse> {
        try {
          const response: EventResponse = await api.put(
            stringUtil.replace(endpoints.schedule.base, {
              ':id': eventId,
            }),
            data
          );
          return Promise.resolve({ success: true, ...response });
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getWalkups(scheduleId: string) {
        try {
          const response: ScheduleWalkup = await api.get(
            stringUtil.replace(endpoints.schedule.walkups, {
              ':id': scheduleId,
            })
          );
          return response;
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async updateWalkups(
        scheduleId: string,
        revenue: number,
        quantity: number
      ) {
        try {
          const response: WalkupUpdateResponse = await api.put(
            queryUtil.toUrl(
              stringUtil.replaceAll(endpoints.schedule.walkups, {
                ':id': scheduleId,
              }),
              {
                revenue: String(revenue),
                quantity: String(quantity),
              }
            )
          );

          return Promise.resolve({ success: true, ...response });
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async transfer(
        scheduleId: string,
        destinationVendorId: string,
        menuId?: string
      ): Promise<VendorResponse> {
        try {
          const response: VendorResponse = await api.patch(
            stringUtil.replaceAll(endpoints.schedule.transfer, {
              ':scheduleId': scheduleId,
            }),
            { destinationVendorId, menuId: menuId ?? '' }
          );
          return { success: true, ...response };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async enableOrdering(
        eventId: string,
        data: EventSyncRequest
      ): Promise<ScheduleResponse> {
        try {
          const response: ScheduleResponse = await api.patch(
            stringUtil.replace(endpoints.schedule.enableOrdering, {
              ':id': eventId,
            }),
            data
          );
          return Promise.resolve({ success: true, ...response });
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async disableOrdering(eventId: string): Promise<ScheduleResponse> {
        try {
          const response: ScheduleResponse = await api.patch(
            stringUtil.replace(endpoints.schedule.disableOrdering, {
              ':id': eventId,
            }),
            {}
          );
          return Promise.resolve({ success: true, ...response });
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};
