import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { Suggestion } from '../../types/shared';

import type { AutocompleteRequest } from '../../types/search';
import type { AutoCompleteResponse } from '../../services/api/types';

export const useLotApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.lots.autocomplete,
            { ...request }
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
