import { dateUtil } from '@goodfynd/react-web.utils.date-util';
import type { Instance, SnapshotOut } from 'mobx-state-tree';
import { cast, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

export const ScheduleStore = types
  .model('ScheduleStore', {
    blocked: types.optional(types.array(types.string), []),
    busy: types.optional(types.boolean, false),
    refetchSchedules: types.optional(types.boolean, false),
    refreshTimeout: types.maybe(types.number),
    search: types.optional(
      types.model().props({
        dateEnd: types.maybe(types.Date),
        dateStart: types.optional(types.Date, dateUtil.startOfDay(new Date())),
        rowsPerPage: types.optional(types.number, 6),
        term: types.optional(types.string, ''),
      }),
      {}
    ),
    vendorId: types.maybe(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    setDateEnd: (date?: Date) => {
      self.search.dateEnd = date || undefined;
    },
    setDateStart: (date: Date) => {
      self.search.dateStart = date;
    },
  }))
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
    setDates: ({ end, start }: IntervalDatesPartial) => {
      self.setDateEnd(end ? new Date(end) : undefined);
      if (start) {
        self.setDateStart(new Date(start));
      }
    },
  }))
  .actions((self) => ({
    block: (id: string) => {
      self.blocked.push(id);
    },
    setRefreshTimeout: (timeout: number) => {
      self.refreshTimeout = timeout;
    },
    setRowsPerPage: (rows: number) => {
      self.search.rowsPerPage = rows;
    },
    setSearchTerm: (term: string) => {
      self.search.term = term;
    },
    toggleRefetchSchedules: (refetch: boolean) => {
      self.refetchSchedules = refetch;
    },
    unblock: (id: string) => {
      self.blocked = cast(self.blocked.filter((orderId) => orderId !== id));
    },
  }));

type ScheduleStoreType = Instance<typeof ScheduleStore>;
export type ScheduleStore = ScheduleStoreType;
type ScheduleStoreSnapshotType = SnapshotOut<typeof ScheduleStore>;
export type ScheduleStoreSnapshot = ScheduleStoreSnapshotType;
