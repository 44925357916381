import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  ReportTypeValue,
  ReportTypeValueType,
} from '@goodfynd/react-web.lib.dashboard';

import { withEnvironment } from '../extensions/with-environment';

import type { FilterMenuOption } from '@goodfynd/react-web.ui.filter-menu';
import type {
  OrderStatusType,
  PaymentStatusType,
} from '@components/reports/report-tabs/types';
const FilterOptionType = types.model().props({
  description: types.optional(types.string, ''),
  image: types.optional(types.string, ''),
  label: types.string,
  value: types.string,
});
export type FilterOption = Instance<typeof FilterOptionType>;
export type SearchProperty = 'search' | 'searchInvoices';
export const ReportStore = types
  .model('ReportStore', {
    blocked: types.optional(types.array(types.string), []),
    busy: types.optional(types.boolean, false),
    search: types.optional(
      types.model().props({
        events: types.optional(types.array(FilterOptionType), []),
        orderStatus: types.optional(types.string, ''),
        orderType: types.optional(types.string, ''),
        paymentStatus: types.optional(types.string, ''),
        reportType: types.optional(ReportTypeValueType as any, 'spreadsheet'),
        rowsPerPage: types.optional(types.number, 6),
        territories: types.optional(types.array(FilterOptionType), []),
        term: types.optional(types.string, ''),
      }),
      {
        reportType: 'spreadsheet',
      }
    ),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
  }))
  .actions((self) => ({
    setBusy: (busy = true) => {
      self.busy = busy;
    },
  }))
  .actions((self) => ({
    block: (id: string) => {
      self.blocked.push(id);
    },
    setEvents: (events: FilterMenuOption[]) => {
      self.search.events = cast(events);
    },
    setOrderStatus: (status: OrderStatusType | string) => {
      self.search.orderStatus = status;
    },
    setOrderType: (type: string) => {
      self.search.orderType = type;
    },
    setPaymentStatus: (status: PaymentStatusType | string) => {
      self.search.paymentStatus = status;
    },
    setReportType: (type: ReportTypeValue) => {
      self.search.reportType = type;
    },
    setRowsPerPage: (rows: number) => {
      self.search.rowsPerPage = rows;
    },
    setSearchTerm: (term: string) => {
      self.search.term = term;
    },
    setTerritories: (territories: FilterMenuOption[]) => {
      const search = self.search;
      search.territories = cast(territories);
    },
    unblock: (id: string) => {
      self.blocked = cast(self.blocked.filter((orderId) => orderId !== id));
    },
    updateTerritories: (option: FilterMenuOption) => {
      const search = self.search;
      const totalSelected = search.territories.length;
      search.territories = cast(
        search.territories.filter(
          (territory) => territory.value !== option.value
        )
      );
      if (search.territories.length === totalSelected) {
        search.territories.push(option);
      }
    },
  }));

type ReportStoreType = Instance<typeof ReportStore>;
export type ReportStore = ReportStoreType;
type ReportStoreSnapshotType = SnapshotOut<typeof ReportStore>;
export type ReportStoreSnapshot = ReportStoreSnapshotType;
