import { useRouter } from 'next/router';
import { useMemo } from 'react';

import routes from '@config/routes';

export function useContextVendorId(vendorIdOverride = '') {
  const router = useRouter();

  const pathVendorId = useMemo(() => {
    let id = '';
    if (
      typeof router.query.state === 'string' &&
      router.pathname.includes(
        routes.dashboard.settings.orderingSettingsWizardPath
      )
    ) {
      // This is where the vendor ID is stored when Stripe onboarding redirects back to Goodfynd for initial vendor setup
      id = router.query.state.split('|')[0];
    }

    return id || router.query.vendorId || router.query.vendor_id;
  }, [
    router.pathname,
    router.query.state,
    router.query.vendorId,
    router.query.vendor_id,
  ]);

  const vendorId: string = useMemo(
    () => vendorIdOverride || (pathVendorId as string) || '',
    [pathVendorId, vendorIdOverride]
  );

  return vendorId;
}
