import { numberUtil } from '@goodfynd/react-web.utils.number-util';

import config from '../config';
import images from '../config/images';
import * as dateUtil from './date-util';

import type { ScheduleItem } from '../types/schedule';
export const getAddressLabel = (
  eventItem: ScheduleItem,
  fallback = 'Get Directions'
) => {
  if (eventItem.pickupLocation?.address) {
    return eventItem.pickupLocation.address;
  }

  if (eventItem.pickupLocation?.city && eventItem.pickupLocation?.state) {
    return `${eventItem.pickupLocation.city}, ${eventItem.pickupLocation.state}`;
  }

  return fallback;
};
export const getEventDateFormat = (eventItem: ScheduleItem) => {
  const parts = [];
  const date = eventItem.date || eventItem.start;
  if (date) {
    parts.push(dateUtil.format(date, config.dateFormats.weekDayMonthDayYear));
  }

  parts.push(dateUtil.getTimeRangeDisplay(eventItem.start, eventItem.end));
  return parts.join(' ');
};

export const getEventTimeLabel = ({ end, start }: IntervalDates) => {
  let label = dateUtil.format(start as Date, config.dateFormats.timeSpace);
  label += config.unicode.dashEnPadded;
  label += dateUtil.format(end as Date, config.dateFormats.timeSpace);
  return label;
};

export const getImageFallback = () => {
  const imageKeys = Object.keys(images.fallbacks.event);
  const imageKey = imageKeys[
    numberUtil.random(0, imageKeys.length - 1)
  ] as keyof typeof images.fallbacks.event;
  return images.fallbacks.event[imageKey];
};

export const getAverageCost = (quantity: number, total: number) => {
  return Math.ceil(total / quantity);
};
