import { styled } from '@goodfynd/react-web.theme';
import TextField from '@mui/material/TextField';

export const StyledMenu = styled('ul', {
  paddingLeft: 0,
});

export const StyledMenuItemBase = styled('div', {
  display: 'flex',
  gap: 16,
  justifyContent: 'space-between',
  svg: {
    marginLeft: 16,
  },
  cursor: 'pointer',
  padding: '8px 16px',
  '&:hover': {
    svg: {
      color: '$Neutral13',
    },
  },
});

export const StyledMenuItem = styled(StyledMenuItemBase, {
  alignItems: 'center',
  svg: {
    margin: '0 4px 0 0 !important',
  },
});

export const StyledTextField = styled(TextField, {
  '&.MuiTextField-root': {
    input: {
      paddingLeft: 0,
    },
  },
});

export const StyledTextFieldContainer = styled('aside', {
  padding: 16,
  paddingBottom: 24,
});
