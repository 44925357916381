import { styled } from '@goodfynd/react-web.theme';

//sidebar
export const StyledSidebarContainer = styled('div', {
  boxShadow: 'hsla(131deg 10% 79% / 5%) 0.75rem 0 0.75rem -0.5rem',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'space-between',
  overflow: 'scroll',
  padding: '0 8px',
  width: 256,
  zIndex: 10,
  '@lgMax': {
    display: 'none',
  },
  variants: {
    collapsed: {
      true: {
        padding: '0 8px',
        width: 72,
      },
    },
  },
});

export const StyledSidebarContent = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const StyledSidebarBottom = styled('div', {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '12px 16px',
  margin: '8px 8px 16px',
  variants: {
    isCollapsed: {
      true: { justifyContent: 'center' },
    },
  },
});

//logo
export const StyledLogoContainer = styled('div', {
  padding: '16px 24px',
  display: 'flex',
  height: 88,
  justifyContent: 'center',
});

//drawer
export const StyledDrawerContainer = styled('div', {
  width: 265,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

export const StyledSwitcherContainer = styled('div', {
  padding: '20px 24px',
});


//header
export const StyledHeaderLeftContainer = styled('div', {
  display: 'flex',
  gap: 24,
});

//content
export const StyledContentContainer = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: '8px 48px 32px',
  position: 'relative',
  '@sm': {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
});

export const StyledPlaceholder = styled('div', {
  textAlign: 'center',
  flexGrow: 1,
  display: 'grid',
  placeContent: 'center',

  variants: {
    border: {
      true: {
        border: 'solid 1px',
      },
    },
    min: {
      true: {
        height: 'fit-content',
        flexGrow: 'unset',
      },
    },
    display: {
      row: {
        display: 'flex',
        gap: 8,
      },
    },
    color: {
      red: { background: '$RedBrand' },
      blue: { background: '$BlueAccent04' },
      green: { background: '$GreenBrand' },
    },
  },
  defaultVariants: {
    border: true,
  },
});
