import React, { useCallback, useMemo } from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Text } from '@goodfynd/react-web.typography.text';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import { StyledContainer } from '../styles';
import type { InputCheckboxListProps } from '../types';

import { StyledLabel } from './styles';
export default function InputCheckboxList<T extends FieldValues>({
  control,
  label,
  name,
  options,
  max = 0,
  ...props
}: InputCheckboxListProps<T>) {
  return (
    <StyledContainer {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => {
          const selectedOptions = useMemo(
            () => (value || []) as InputOption[],
            [value]
          );
          const handleSelect = useCallback(
            (selectedOption: InputOption) => {
              const alreadySelected = selectedOptions.find(
                (option) => option.value === selectedOption.value
              );

              if (alreadySelected) {
                const remaining = selectedOptions.filter(
                  (item: InputOption) => item.value !== selectedOption.value
                );
                onChange(remaining as PathValue<T, Path<T>>);
              } else if (max === 0 || selectedOptions.length !== max) {
                onChange([...selectedOptions, selectedOption] as PathValue<
                  T,
                  Path<T>
                >);
              } else {
                //TODO: Show max reached message in snackbar?
              }
            },
            [onChange, selectedOptions]
          );
          return (
            <FormControl fullWidth variant="outlined">
              {label &&
                (typeof label === 'string' ? (
                  <FormLabel component="legend">{label}</FormLabel>
                ) : (
                  label
                ))}

              {options.map((option: any) => {
                return (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        onChange={() => handleSelect(option)}
                        checked={
                          !!selectedOptions?.find(
                            (selectedOption) =>
                              option.value === selectedOption.value
                          )
                        }
                      />
                    }
                    label={
                      <StyledLabel>
                        <Text>{option.label}</Text>
                        <Text>{option.labelRight}</Text>
                      </StyledLabel>
                    }
                  />
                );
              })}
            </FormControl>
          );
        }}
      />
    </StyledContainer>
  );
}
