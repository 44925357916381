import { useMemo } from 'react';

import { UserRole } from '../../types/enums';

import { useHasPermissions } from './usePermissions';

export const FeatureBlock = ({ children, roles }: FeatureBlockProps) => {
  const userRoles = useMemo(() => {
    const roleList = typeof roles === 'string' ? [roles] : roles;
    return roleList.map((role) => {
      switch (role) {
        case 'admin':
          return UserRole.Admin;

        case 'intern':
          return UserRole.Intern;

        case 'org-manager':
          return UserRole.OrganizationManager;

        case 'org-owner':
          return UserRole.OrganizationOwner;

        case 'restricted-admin':
          return UserRole.Admin;

        case 'super-admin':
          return UserRole.SuperAdmin;

        case 'vendor-employee':
          return UserRole.VendorEmployee;

        case 'vendor-manager':
          return UserRole.VendorManager;

        case 'vendor-owner':
          return UserRole.VendorOwner;

        default:
          return UserRole.Admin;
      }
    });
  }, [roles]);

  const hasPermission = useHasPermissions(userRoles);
  if (hasPermission) {
    return <>{children}</>;
  }

  return null;
};
