import React, { useMemo } from 'react';
import CmsPageContent from '@components/cms/CmsPageContent';
import routes from '@config/routes';
import { ButtonLink, FlexView } from '@goodfynd/react-web.lib.ui';

import { LayoutWithHeader } from '..';

const AuthFallback = () => {
  const loginHref = useMemo(() => {
    const loginHref = routes.account.signIn;
    if (window.location.pathname === '/') {
      return loginHref;
    }

    return `${loginHref}?returnUrl=${window.location.href}`;
  }, []);

  return (
    <LayoutWithHeader>
      <FlexView align="center" direction="column" margin={40} size={1}>
        <CmsPageContent slug="vendor-dashboard-login" />
        <ButtonLink content="Log in here" href={loginHref} />
      </FlexView>
    </LayoutWithHeader>
  );
};

export default AuthFallback;
