import { decode } from 'html-entities';

import app from './app';
import env from './env';
import routes from './routes';

export enum AppMode {
  Admin = 1,
  Normal = 0,
  Testing = 2,
}

export const emojis = {
  fries: '🍟',
  happy: '😃',
  hundred: '💯',
  sad: '😢',
  smile: '😊',
  surprised: '😱',
};

export const unicode = {
  bullet: '\u2022',
  bulletPadded: decode('&nbsp;\u2022&nbsp;'),
  bulletLarge: '\u2B24',
  centerDot: decode('\u00B7'),
  centerDotPadded: decode('&nbsp;\u00B7&nbsp;'),
  check: '\u2713',
  dashEm: '\u2014',
  dashEmPadded: decode('&nbsp;\u2014&nbsp;'),
  dashEn: '\u2013',
  dashEnPadded: decode('&nbsp;\u2013&nbsp;'),
  infinity: '\u221E',
  minus: '\u2212',
  plus: '\u002B',
  plusHeavy: '\u2795',
  plusLarge: '\uFF0B',
  rightAngle: '\u203A',
  rightAngleDouble: '\u00BB',
  rightArrowhead: '\u003E',
  space: decode('&nbsp;'),
  tm: decode('&trade;'),
};

export default {
  app,
  env,
  routes,
  coordinates: '39.1157,-77.5633',
  dateFormats: {
    date: 'do',
    dayHourM: 'eee, ha',
    dayOfWeek: 'EEEE',
    dayOfWeekShort: 'EEE',
    dayTime: 'MM/dd/yyyy h:mm a',
    hour: 'h',
    hourLeadingZero: 'hh',
    hourLeadingZeroM: 'hh a',
    hourM: 'ha',
    hourMin: 'h:mm',
    hour24: 'H',
    hour24LeadingZero: 'HH',
    hourSpaceM: 'h a',
    meridiem: 'aaa',
    minute: 'mm',
    month: 'MMMM',
    monthDay: 'MMM do',
    monthDayShort: 'M/dd',
    monthDayYear: 'MM/dd/yyyy',
    monthDayYearShort: 'M/d/yy',
    monthDayYearLong: 'MMM do, yyyy',
    monthDayYearTime: 'MMM dd, yyyy h:mm a',
    monthDayYearTimeLong: 'MMMM dd, yyyy h:mm a',
    monthShort: 'MMM',
    monthYear: 'MMMM yyyy',
    time: 'h:mma',
    timeSpace: 'h:mm a',
    weekDayShort: 'eee',
    weekDay: 'eeee',
    weekDayMonthDay: 'eee, MMM do',
    weekDayMonthDayTime: 'eee, MMM do, h:mm a',
    weekDayMonthDayYear: 'eee, MMM dd, yyyy',
    weekDayMonthDayYearLong: 'eeee, MMMM dd, yyyy',
    weekDayMonthDayYearShort: 'eee, MMM do, yyyy',
    weekDayMonthDayYearTime: 'EEE, MMM dd, yyyy h:mm a',
    weekDayMonthDayYearTimeLong: 'EEEE, MMMM dd, yyyy h:mm a',
    year: 'yyyy',
    yearMonthDay: 'yyyyMMdd',
  },
  defaultLocation: {
    coordinates: '38.805, -77.047',
    latitude: 38.805,
    longitude: -77.047,
  },
  emojis,
  queryKeys: {
    auth: 'fynd.auth',
    cart: 'fynd.query.cart',
    cms: 'fynd.query.cms',
    dietary: 'fynd.query.dietary',
    employee: 'fynd.query.employee',
    event: 'fynd.query.vendor.event',
    eventMerchants: 'fynd.query.vendor.event.merchants',
    favoriteVendors: 'fynd.query.favorite.vendors',
    licenseTypes: 'fynd.query.licenses',
    lotEvents: 'fynd.query.lot.events',
    menuSearch: 'fynd.query.menuSearch',
    merchant: 'fynd.query.merchant',
    openVendors: 'fynd.query.open.vendors',
    order: 'fynd.query.order',
    orders: 'fynd.query.orders',
    orderSearch: 'fynd.query.orders.search',
    orderSearchOrg: 'fynd.query.orders.search.org',
    orderSearchAll: 'fynd.query.orders.search.all',
    orderSearchEventSales: 'fynd.query.orders.search.event.sales',
    orderSearchInvoices: 'fynd.query.orders.search.invoices',
    orderSearchOrgInvoices: 'fynd.query.orders.search.org.invoices',
    ordersOverview: 'fynd.query.orders.overview',
    ordersOverviewAdmin: 'fynd.query.orders.overview.admin',
    ordersReport: 'fynd.query.orders.report',
    ordersReportInvoices: 'fynd.query.orders.report.invoices',
    ordersReportOrg: 'fynd.query.orders.report.org',
    ordersReportOrgInvoices: 'fynd.query.orders.report.org.invoices',
    ordersReportCustomerOverview: 'fynd.query.orders.report.customer.overview',
    ordersReportItems: 'fynd.query.orders.report.items',
    ordersReportPopularProducts: 'fynd.query.orders.report.popular.products',
    ordersReportSummary: 'fynd.query.orders.report.sumamry',
    ordersReportTrend: 'fynd.query.orders.report.trend',
    organization: 'fynd.query.organization',
    organizationContactDetails: 'fynd.query.organizationContactDetails',
    organizationUsers: 'fynd.query.organization.users',
    organizationStructure: 'fynd.query.organization.structure',
    organizationTerritories: 'fynd.query.organization.territories',
    payoutIntervals: 'fynd.query.payoutintervals',
    payouts: 'fynd.query.payouts',
    payoutTransactions: 'fynd.query.payouts.transactions',
    roles: 'fynd.query.roles',
    searchEvents: 'search-events',
    searchProducts: 'search-products',
    searchVendors: 'search-vendors',
    schedule: 'fynd.query.schedule',
    scheduleSearch: 'fynd.query.schedule.search',
    scheduleSearchOrgUpcoming: 'fynd.query.schedule.search.upcoming.org',
    scheduleSearchOverview: 'fynd.query.schedule.search.overview',
    scheduleSearchPast: 'fynd.query.schedule.search.past',
    scheduleSearchUpcoming: 'fynd.query.schedule.search.upcoming',
    scheduleWalkup: 'fynd.query.schedule.walkup',
    transactions: 'fynd.query.transactions',
    usersActive: 'fynd.query.users.active',
    vendor: 'fynd.query.vendor',
    vendorAgreements: 'fynd.query.vendor.agreements',
    vendorBusinessInfo: 'fynd.query.vendor.businessinfo',
    vendorCategories: 'fynd.query.vendor.categories',
    vendorCount: 'fynd.query.vendor.count',
    vendorEvents: 'fynd.query.vendor.events',
    vendorFrequentLocations: 'fynd.query.vendor.frequentlocations',
    vendorLicense: 'fynd.query.vendor.license',
    vendorMap: 'fynd.query.vendor.map',
    vendorProducts: 'fynd.query.vendor.products',
    vendorProductSearch: 'fynd.query.vendor.product.search',
    vendorSettings: 'fynd.query.vendor.settings',
    vendorSummary: 'fynd.query.vendor.summary',
    vendorTaxes: 'fynd.query.vendor.taxes',

    product: 'fynd.query.product',
    categories: 'fynd.query.categories',
    vendorProductOptions: 'fynd.query.vendor.product.options',
    category: 'fynd.query.category',
    productGroup: 'fynd.query.product-group',
    organizationProducts: 'fynd.query.organization.products',
    organizationVendorProducts: 'fynd.query.organization.vendor.products',
    organizationProductOptions: 'fynd.query.organization.product.options',
    organizationVendorProductOptions:
      'fynd.query.organization.vendor.product.options',
    organizationCategories: 'fynd.query.organization.categories',
    organizationTaxSettings: 'fynd.query.organization.taxsettings',
    organizationVendorCategories: 'fynd.query.organization.vendor.categories',
    organizationProductGroup: 'fynd.query.organization.product-group',
    organizationVendorProductGroup:
      'fynd.query.organization.vendor.product-group',
    organizationProductSearch: 'fynd.query.organization.product.search',
    organizationSubscriptions: {
      embeddableWidgets: {
        configuration: {
          embeddableMaps:
            'fynd.query.organization.organizationSubscriptions.emeddableWidgets.configuration.embeddableMaps',
        },
      },
    },
  },
  storageKeys: {
    appleAuth: 'fynd.apple.auth',
    colorScheme: 'fynd.color.scheme',
    guestOrder: 'fynd.guest.order',
    rootState: 'fynd.root',
    userLocation: 'fynd.user.location',
  },
  unicode,
  images: {
    itemPlaceholder: '/images/fallbacks/product-fallback.jpg',
  },
  features: {
    masterCatalog: 'master-catalog',
    organizations: 'organizations',
    vendorMenus: 'vendor-menus',
    personnel: 'personnel',
    reports: 'reports',
    schedules: 'schedules',
    territories: 'territories',
    vendors: 'vendors',
    quotes: 'quotes',
    embeddableSchedules: 'embeddable-schedules',
    embeddableTerritories: 'embeddable-territories',
  },
  subscriptions: {
    organizationDashboard: 'organization-dashboard',
  },
};

export function showOopsAlert(): void {
  alert(`An unknown error has occurred ${emojis.sad}.`);
}

export function showTimedOutAlert(): void {
  alert(`Application timed out ${emojis.sad}.`);
}
