import React, { useCallback, useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import type { FieldValues, Path, PathValue } from 'react-hook-form';
import { useAlertDialog } from '@goodfynd/react-web.ui.dialog';
import { Icon } from '@goodfynd/react-web.ui.icon';
import { FormHelperText, InputLabel } from '@mui/material';
import { toBase64 } from 'utils/file-util';

import ImageCropper from '../ImageCropper';

import { StyledFileContainer, StyledHelperText, StyledImage } from './styles';
import type { InputFileUploadComponentProps } from './types';

export function InputFileUploadComponent<T extends FieldValues = FieldValues>({
  helperText,
  label,
  accept,
  errorMessage,
  crop,
  onChange,
  renderPreview,
  value,
}: InputFileUploadComponentProps<T>) {
  const [image, setImage] = useState<string | undefined>();

  const openAlert = useAlertDialog();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles?.length) {
        openAlert({
          title: 'Invalid File',
          message: `File "${rejectedFiles[0].file.name}" does not match the accepted types: ${accept}`,
        });
      } else if (acceptedFiles?.length) {
        if (crop) {
          setImage(URL.createObjectURL(acceptedFiles[0]));
        } else {
          onChange((await toBase64(acceptedFiles[0])) as PathValue<T, Path<T>>);
        }
      }
    },
    [accept, crop, onChange, openAlert]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    onDrop,
  });

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <FormHelperText>{helperText}</FormHelperText>

      {!value ? (
        <StyledFileContainer {...getRootProps()}>
          <Icon name="image_upload" />
        </StyledFileContainer>
      ) : renderPreview ? (
        renderPreview({
          file: value as string,
          openFileDialog: open,
        })
      ) : (
        <StyledFileContainer {...getRootProps()}>
          <StyledImage alt="file upload" src={value as string} />
        </StyledFileContainer>
      )}

      <input {...getInputProps()} />

      {!!errorMessage && (
        <StyledHelperText error>{errorMessage}</StyledHelperText>
      )}

      {crop && (
        <ImageCropper
          image={image}
          open={Boolean(image)}
          close={() => {
            setImage(undefined);
          }}
          onSave={(src) => {
            onChange(src as PathValue<T, Path<T>>);
            setImage(undefined);
          }}
        />
      )}
    </>
  );
}
