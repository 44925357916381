import React from 'react';
import InputMask from 'react-input-mask';
import { FormControl, FormHelperText, InputLabel, NoSsr } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import type { InputMaskControlProps } from './types';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  { ...props }: any,
  ref: React.LegacyRef<InputMask>
) {
  return <InputMask {...props} ref={ref} />;
});

export default React.forwardRef(function InputMaskControl(
  { error, helperText, mask, ...props }: InputMaskControlProps,
  ref: any
) {
  return (
    <NoSsr>
      <FormControl error={!!error} fullWidth>
        {props.label && (
          <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        )}

        <OutlinedInput
          ref={ref}
          {...props}
          inputComponent={TextMaskCustom}
          inputProps={{ mask }}
        />
        <FormHelperText>{error?.message || helperText}</FormHelperText>
      </FormControl>
    </NoSsr>
  );
});
