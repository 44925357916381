import type {
  FooterLinkGroup,
  FooterSocialLink,
} from '@goodfynd/react-web.layout.footer/types';

import config from '../../../config';
import routes, { externalRoutes } from '../../../config/routes';

export const LEGAL_LINKS: FooterLinkGroup = {
  name: 'Legal',
  menu_items: [
    {
      label: 'Privacy Policy',
      url: '/legal/privacy',
    },
    {
      label: 'Fulfillment Policy',
      url: '/legal/fulfillment',
    },
    {
      label: 'Terms of Service',
      url: '/legal/tos',
    },
    {
      label: 'Cookie Policy',
      url: '/legal/cookie',
    },
    {
      label: 'Privacy Notice California',
      url: '/legal/privacy-california',
    },
  ],
};

export const SOCIALS: FooterSocialLink[] = [
  {
    alt: 'Goodfynd on Twitter',
    icon_name: 'twitter',
    url: routes.social.twitter,
  },
  {
    alt: 'Goodfynd on LinkedIn',
    icon_name: 'linkedin',
    url: routes.social.linkedIn,
  },
  {
    alt: 'Goodfynd on Instagram',
    icon_name: 'instagram',
    url: routes.social.instagram,
  },
  {
    alt: 'Goodfynd on Facebook',
    icon_name: 'facebook',
    url: routes.social.facebook,
  },
];

export const FOOTER_NAV: FooterLinkGroup[] = [
  {
    name: 'Let Us Help You',
    menu_items: [
      {
        label: 'Find a Truck',
        url: routes.search.vendors,
      },
      {
        label: 'Book a Truck',
        url: externalRoutes.events.consumerLanding,
      },
      {
        label: 'List Your Truck',
        url: routes.dashboard.add,
      },
    ],
  },
  {
    name: 'Your Account',
    menu_items: [
      {
        label: 'Account Details',
        url: routes.account.home,
      },
      {
        hide: true,
        label: 'Order History',
        url: routes.account.orders,
      },
    ],
  },
  {
    name: 'Support',
    menu_items: [
      {
        label: 'Help',
        hide: true,
        url: routes.help.home,
      },
      {
        label: 'Chat With Us',
        onClick: () =>
          window.drift?.api.startInteraction({
            interactionId: config.env.DRIFT_INTERACTION_ID,
          }),
      },
    ],
  },
  {
    name: 'Get to Know Us',
    menu_items: [
      {
        label: 'Careers',
        url: routes.careers,
      },
      {
        label: 'Blog',
        url: routes.blog,
      },
    ],
  },
];
