import React, { useState } from 'react';
import { IconButton } from '@goodfynd/react-web.ui.button';
import { Text } from '@goodfynd/react-web.ui.text';

import { StyledContainer, StyledValue } from './styles';
import type { InputQuantityProps } from './types';

export default function InputQuantity({
  defaultValue,
  disabled,
  max,
  min = 0,
  onChange,
  ...props
}: InputQuantityProps) {
  const [value, setValue] = useState<number>(defaultValue || min);

  const handleQuantityChange = (_e: ClickButtonEvent, increase = true) => {
    onChange?.(value + (increase ? 1 : -1));

    if (increase) {
      setValue((curValue: number) => curValue + 1);
    } else {
      setValue((curValue: number) => curValue - 1);
    }
  };
  return (
    <StyledContainer {...props}>
      <IconButton
        color="neutral"
        disabled={disabled || value === min}
        icon="remove"
        onClick={(e: ClickButtonEvent) => handleQuantityChange(e, false)}
      />

      <StyledValue>
        <Text align="center" as="subhead2">
          {value}
        </Text>
      </StyledValue>

      <IconButton
        color="neutral"
        disabled={disabled || value === max}
        icon="add"
        onClick={handleQuantityChange}
      />
    </StyledContainer>
  );
}
