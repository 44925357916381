import { StyledText as StyledTextBase } from '@goodfynd/react-web.typography.text/dist/styles';

import { styled } from '../../theme/stitches.config';

export const StyledContainer = styled('div', {
  backgroundColor: '$RedBrand',
  borderRadius: 12,
  padding: '1px 8px',
});

export const StyledText = styled(StyledTextBase, {
  color: '$Neutral00',
  fontSizeRem: 12,
  fontWeight: 800,
  lineHeightRem: 18,
});
