import { useMemo } from 'react';

import { stringUtil } from '@goodfynd/react-web.lib.utils';

import endpoints from '../../services/api/endpoints';
import { VendorProductOptionsUpdateResponse } from '../../services/api/types';
import useFetch from '../../services/api/useFetch';
import { AddUpdateProductOptionV2Request } from '../../stores/product-store/types';
import { OrderingOptionGroup } from '../../types/products';

export const useOrganizationProductOptionsApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async getOptions(organizationId: string) {
        try {
          const data: OrderingOptionGroup[] = await api.get(
            endpoints.productOptions.organization.search,
            {
              organizationId,
              includeHidden: true,
            }
          );

          return { success: true, productOptions: data };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async getVendorOptions(vendorId: string) {
        try {
          const data: OrderingOptionGroup[] = await api.get(
            stringUtil.replaceAll(
              endpoints.productOptions.organization.vendor,
              {
                ':vendorId': vendorId,
              }
            ),
            {
              includeHidden: true,
            }
          );

          return { success: true, productOptions: data };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async addOption(request: AddUpdateProductOptionV2Request) {
        try {
          const data: OrderingOptionGroup = await api.post(
            endpoints.productOptions.organization.search,
            request
          );

          return { success: true, productOption: data };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async updateOption({ id, ...request }: AddUpdateProductOptionV2Request) {
        try {
          const {
            productOption,
            productOptions,
          }: VendorProductOptionsUpdateResponse = await api.put(
            stringUtil.replaceAll(endpoints.productOptions.organization.base, {
              ':id': id!,
            }),
            request
          );

          return { success: true, productOption, productOptions };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },

      async deleteOption(id: string) {
        try {
          const {
            productOption,
            productOptions,
          }: VendorProductOptionsUpdateResponse = await api.delete(
            stringUtil.replaceAll(endpoints.productOptions.organization.base, {
              ':id': id!,
            })
          );

          return { success: true, productOption, productOptions };
        } catch (error: unknown) {
          return Promise.reject({ success: false, error });
        }
      },
    };
  }, [api]);
};
