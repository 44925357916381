import React, { useCallback, useRef, useState } from 'react';
import type { ReactCropperElement } from 'react-cropper';
import Cropper from 'react-cropper';
import { Button, IconButton } from '@goodfynd/react-web.ui.button';
import { Text } from '@goodfynd/react-web.ui.text';
import { Slider, Stack } from '@mui/material';

import Modal from '../../Modal';

import { StyledButtonContainer, StyledContainer } from './styles';
import type { ImageCropContainerProps, ImageCropperProps } from './types';

export default function ImageCropper({
  image,
  onSave,
  ...props
}: ImageCropContainerProps) {
  return (
    <Modal
      header={<Text as="heading5">Image crop</Text>}
      containerCss={{
        width: 'fit-content',
      }}
      {...props}
    >
      <ImageCropperConent image={image} onSave={onSave} />
    </Modal>
  );
}

const ImageCropperConent = ({ image, onSave }: ImageCropperProps) => {
  const cropperRef = useRef<ReactCropperElement>(null);

  const [zoom, setZoom] = useState(0);
  const [minZoom, setMinZoom] = useState(0);
  const [rotation, setRotation] = useState(0);

  const handleSave = useCallback(async () => {
    if (cropperRef.current) {
      onSave(cropperRef.current.cropper.getCroppedCanvas().toDataURL());
    }
  }, [onSave]);

  const handleZoomIn = useCallback(() => {
    setZoom(Math.min(4, zoom + 0.25));
  }, [zoom]);

  const handleZoomOut = useCallback(() => {
    setZoom(Math.max(minZoom, zoom - 0.25));
  }, [zoom, minZoom]);

  const rotate = useCallback(
    (degree: number) => {
      cropperRef.current?.cropper.rotateTo(degree);
      setRotation(degree);
    },
    [setRotation]
  );

  const handleRotateLeft = useCallback(() => {
    const degree = rotation - 90;
    rotate(Math.max(Math.floor(degree / 90) * 90, -180));
  }, [rotate, rotation]);

  const handleRotateRight = useCallback(() => {
    const degree = rotation + 90;
    rotate(Math.min(Math.ceil(degree / 90) * 90, 180));
  }, [rotate, rotation]);

  return (
    <StyledContainer>
      <Cropper
        autoCropArea={1.0}
        cropBoxMovable={false}
        cropBoxResizable={false}
        dragMode="move"
        guides={true}
        initialAspectRatio={16 / 9}
        ref={cropperRef}
        src={image}
        style={{ height: '294px', width: '525px' }}
        viewMode={3}
        zoomOnTouch={false}
        zoomOnWheel={false}
        zoomTo={zoom}
        ready={() => {
          const imageData = cropperRef.current?.cropper.getImageData();
          if (imageData) {
            const minZoom = imageData.width / imageData.naturalWidth;
            setMinZoom(minZoom);
            setZoom(minZoom);
          }
        }}
      />
      <div>
        <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 1 }}>
          <IconButton icon="zoom_out" onClick={handleZoomOut} />
          <Slider
            max={4}
            min={minZoom}
            onChange={(_, value) => setZoom(Number(value))}
            step={0.01}
            value={zoom}
          />
          <IconButton icon="zoom_in" onClick={handleZoomIn} />
        </Stack>

        <Stack alignItems="center" direction="row" spacing={2} sx={{ mb: 1 }}>
          <IconButton icon="rotate_left" onClick={handleRotateLeft} />
          <Slider
            max={180}
            min={-180}
            value={rotation}
            onChange={(_, value) => {
              rotate(value as number);
            }}
          />
          <IconButton icon="rotate_right" onClick={handleRotateRight} />
        </Stack>

        <StyledButtonContainer>
          <Button onClick={handleSave}>Add Image</Button>
        </StyledButtonContainer>
      </div>
    </StyledContainer>
  );
};
