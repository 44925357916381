import React from 'react';

import { useVendorApi } from '../../../hooks/api';
import FilterMenuAutocompleteAsync from '../../FilterMenuAutocompleteAsync';

import type { FilterMenuAutocompleteVendorsProps } from './types';

export default function FilterMenuAutocompleteVendors({
  idPropertyName = 'id',
  onChange,
  ...props
}: FilterMenuAutocompleteVendorsProps) {
  const api = useVendorApi();
  return (
    <FilterMenuAutocompleteAsync
      label="Vendor"
      onChange={onChange}
      placeholder="Find vendors..."
      getOptions={async (request) => {
        const { results } = await api.autocomplete(request);
        return results.map((result) => ({
          description: result.nameId,
          label: result.name,
          value: result[idPropertyName] || result.id,
        }));
      }}
      {...props}
    />
  );
}
