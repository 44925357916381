import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import { CategoriesResponse, CategoryResponse } from '../../services/api/types';
import useFetch from '../../services/api/useFetch';
import { AddUpdateCategoryRequest } from '../../stores/product-store/types';
import { CategoryItem } from '../../types/shared';

export const useCategoryApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async get(id: string): Promise<CategoryResponse> {
        try {
          const data: CategoryItem = await api.get(
            stringUtil.replaceAll(endpoints.categories.base, {
              ':id': id,
            })
          );
          return { success: true, category: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async list(vendorId: string): Promise<CategoriesResponse> {
        try {
          const data: CategoryItem[] = await api.get(
            endpoints.categories.search,
            { vendorId }
          );
          return { success: true, categories: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async add(category: AddUpdateCategoryRequest) {
        try {
          const data: CategoryItem = await api.post(
            endpoints.categories.search,
            category
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async update(category: AddUpdateCategoryRequest) {
        try {
          const data: CategoryItem = await api.put(
            stringUtil.replaceAll(endpoints.categories.base, {
              ':id': category.id!,
            }),
            category
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async delete(categoryId: string) {
        try {
          const data: CategoryResponse = await api.delete(
            stringUtil.replaceAll(endpoints.categories.delete, {
              ':id': categoryId,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async toggle(categoryId: string, visible: boolean) {
        try {
          const data: CategoryResponse = await api.patch(
            stringUtil.replaceAll(endpoints.categories.toggle, {
              ':id': categoryId,
              ':visible': String(visible),
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
      async export(categoryId: string) {
        try {
          const data: ResponseBase = await api.post(
            stringUtil.replaceAll(endpoints.categories.organization.export, {
              ':id': categoryId,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },
    };
  }, [api]);
};
