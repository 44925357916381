import type { CSS } from '@stitches/react/types/css-util';

import { format, startOfDay } from '@goodfynd/react-web.utils.date-util';
import { asCurrency, getCurrency } from '@goodfynd/react-web.utils.number-util';

import config from '../../config';
import { DefaultColors } from '../../theme';
import { displayDay } from '../string-util';

import type { CartOrder, OrderType } from '@goodfynd/react-web.types';
export const calculateInstantPayoutFee = (payoutAmount: number) => {
  const feePercentage = 0.0125;
  return Number(
    getCurrency(payoutAmount * feePercentage, { thousandSeparated: false })
  );
};

export const getCancelReasons = (order: CartOrder) => {
  return order.type === 'invoice'
    ? []
    : [
        {
          label: 'Ran out of...',
          value: `Sorry! We ran out of \n\n- ${order.merchant.name}`,
        },
        {
          label: 'Mechanical Issues',
          value: `Sorry our truck is experiencing mechanical issues and we have to reschedule ${config.emojis.sad}.\n\n- ${order.merchant.name}`,
        },
        {
          label: 'Rescheduling',
          value: `Sorry, this event is being rescheduled for a later date ${config.emojis.sad}.\n\n- ${order.merchant.name}`,
        },
      ];
};

export const getCustomMessages = (order: CartOrder) => {
  return order.type === 'invoice'
    ? []
    : [
        {
          label: 'Order ready!',
          value: `Great news from Goodfynd! Your order for ${order.merchant.name} is ready for pickup.`,
        },
        {
          label: 'Ran out of...',
          value: `Sorry! We ran out of\n\n- ${order.merchant.name}`,
        },
        {
          label: 'Running late',
          value: `Sorry we're running late! We will get your food to you ASAP.\n\n- ${order.merchant.name}`,
        },
      ];
};

export const getIncludedTax = (price: number, taxRate: number) => {
  const priceBeforeTax = getTaxIncludedPrice(price, taxRate);
  const taxAmount = price - priceBeforeTax;
  return asCurrency(taxAmount);
};

export const getTaxIncludedPrice = (price: number, taxRate: number) => {
  const taxDivisor = 1 + taxRate;
  const priceBeforeTax = price / taxDivisor;
  return asCurrency(priceBeforeTax);
};

export const getInvoicePaymentUrl = (id: string, fallbackUrl?: string) => {
  switch (config.env.NAME_LOWER) {
    case 'development':
    case 'local':
    case 'staging':
      return `https://invoice.stage.goodfynd.com/${id}`;

    case 'production':
      return `https://invoice.goodfynd.com/${id}`;

    default:
      return fallbackUrl;
  }
};

export const getInvoicedTime = (order: CartOrder): string => {
  const isPaid = order.step === 4;
  const isReminded = order.totalNotifications > 0;
  let action = isReminded ? 'Reminded' : 'Sent';
  if (isPaid) {
    action = 'Paid';
  }

  const time = isPaid || isReminded ? order.timeUpdated : order.time;
  const dayDisplay = displayDay(time, config.dateFormats.time);
  switch (dayDisplay) {
    case 'Today':
      return `${action} Today`;

    case 'Yesterday':
      return `${action} Yesterday`;

    default:
      return `${action} ${format(time, config.dateFormats.monthDayYear)}`;
  }
};

export const getOrderName = (
  order: CartOrder,
  includePhone = false
): string => {
  const ticketNumber = order.ticketNumber
    ? `Ticket #${order.ticketNumber}`
    : '';
  return order.customer?.fullName || order.customer?.phone || ticketNumber
    ? order.customer?.fullName ||
        (includePhone ? order.customer?.phone : '') ||
        ticketNumber
    : 'No Customer';
};

export const getOrderedTime = (order: CartOrder): string => {
  const time = order.time;
  const dayDisplay = displayDay(time, config.dateFormats.time);
  const timeDisplay = format(time, config.dateFormats.time);
  switch (dayDisplay) {
    case 'Today':
      return `Ordered at ${timeDisplay}`;

    case 'Yesterday':
      return `Ordered yesterday at ${timeDisplay}`;

    default:
      return `Ordered ${format(
        time,
        config.dateFormats.monthDayYear
      )} at ${timeDisplay}`;
  }
};

export const getPickupTime = (order: CartOrder): string => {
  const time = new Date(order.pickupTime || order.time);
  const dayDisplay = displayDay(time, config.dateFormats.monthDayYear);

  if (time < startOfDay(new Date())) {
    return dayDisplay;
  }

  switch (dayDisplay) {
    case 'Today':
      return '';

    default:
      return getOrderedTime(order);
  }
};

export type OrderStatusLabel =
  | 'Abandoned'
  | 'Canceled'
  | 'Completed'
  | 'New'
  | 'Notified'
  | 'Not Started'
  | 'Partially Refunded'
  | 'Refunded'
  | 'Reminded'
  | 'Sent'
  | 'Started'
  | 'Unknown';
export const getStatusLabel = (
  order: CartOrder,
  isGoodfyndAdmin = false
): OrderStatusLabel => {
  const isInvoice = order.type === 'invoice';
  const orderTotal = isGoodfyndAdmin ? order.total : order.vendorTotal;
  const sectionDictionary: { [key: string]: OrderStatusLabel } = {
    '1': 'New',
    '2': isInvoice ? 'Sent' : 'Started',
    // '3': isInvoice ? 'Sent' : 'Not Started',
    '3': isInvoice ? 'Reminded' : 'Notified',
    '4': 'Completed',
    '91': 'Canceled',
    '93': 'Refunded',
    '95': 'Abandoned',
  };
  return order.refund > 0 && order.refund < orderTotal
    ? 'Partially Refunded'
    : sectionDictionary[order.step.toString()] || 'Unknown';
};

export const getStatusTagCss = (status: string): CSS => {
  if (typeof status !== 'string') {
    return {};
  }

  switch (status?.toLowerCase()) {
    case 'complete':
    case 'paid':
    case 'paid_external':
    case 'walk up':
      return {
        backgroundColor: '$GreenLightest',
        color: DefaultColors.GreenBrand,
      };

    case 'canceled':
    case 'expired':
    case 'refunded':
    case 'voided':
      return {
        backgroundColor: '$RedLightest',
        color: '$RedBrand',
      };

    case 'partially_refunded':
    case 'ready':
      return {
        backgroundColor: '$YellowBrand',
        color: DefaultColors.Neutral13,
      };

    default:
      return {};
  }
};

export const getSum = (
  orders: CartOrder[],
  property:
    | (keyof CartOrder & 'itemTotal')
    | 'subTotal'
    | 'taxAmount'
    | 'total'
    | 'totalFees'
    | 'vendorTotal' = 'vendorTotal'
): number => {
  return orders.map((order) => order[property]).reduce((a, b) => a + b, 0);
};

export const getTypeLabel = (type: OrderType): string => {
  switch (type) {
    case 'donation':
      return 'Donation';

    case 'invoice':
      return 'Invoice';

    case 'online':
      return 'Online';

    case 'quote':
      return 'Quote';

    case 'walkup':
      return 'Credit';

    case 'walkup-cash':
      return 'Cash';

    case 'walkup-report':
      return 'Report';

    default:
      return 'Unknown';
  }
};
