import isEqual from 'lodash/isEqual';
import { useCallback, useMemo } from 'react';
import { QueryKey, useQuery, useQueryClient } from 'react-query';

import config from '../config';
import { useVendorApi } from '../hooks/api';
import { minutesToMS } from '../utils/number-util';

import type { MerchantResponse } from '../services/api/types';
import type { MerchantItem } from '@goodfynd/react-web.types';

type MerchantOptions = {
  enabled?: boolean;
  id?: string;
  track?: boolean;
  vendorId: string;
};

/**
 * Do NOT use directly, use the output from the useVendor hook instead
 */
export function useMerchant({
  enabled = false,
  id,
  vendorId,
}: MerchantOptions) {
  const queryClient = useQueryClient();

  const api = useVendorApi();
  const profileId = id || vendorId;

  const queryKey = useMemo<QueryKey>(
    () => [config.queryKeys.merchant, profileId],
    [profileId]
  );
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    MerchantResponse,
    unknown
  >(
    queryKey,
    async () => {
      const response = await api.getMerchant(profileId as string);
      const profile = response.merchant;
      console.debug('Merchant: ', profile.nameId);
      return response;
    },
    {
      enabled: enabled && !!profileId,
      refetchInterval: minutesToMS(5),
      refetchOnReconnect: true,
      cacheTime: minutesToMS(5),
    }
  );

  const invalidate = useCallback(async () => {
    await queryClient.invalidateQueries({
      predicate: (query) => isEqual(query.queryKey, queryKey),
    });
  }, [queryClient, queryKey]);

  return {
    invalidate,
    isFetched,
    isFetching,
    isLoading,
    refetch,
    merchant: (data?.merchant || {}) as MerchantItem,
  };
}
