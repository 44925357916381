import React, { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { InputComboBox } from '@goodfynd/react-web.lib.forms';
import { numberUtil } from '@goodfynd/react-web.lib.utils';

import { useVendor } from '../../../context/VendorContext';
import useVendorTaxes from '../../../hooks/useVendorTaxes';

import type { InputTaxProps } from './types';
export const createTaxOption = (text: string | number): InputOption => {
  const label = typeof text === 'string' ? text.replace('%', '') : text;
  const value = numberUtil.roundTo(Number(label), 4);

  return {
    label: `${value} %`,
    value,
  };
};
export default function InputTax<T extends FieldValues>({
  defaultTax = 0,
  options = [],
  ...props
}: InputTaxProps<T>) {
  const { merchant, vendorId } = useVendor();
  const { taxes } = useVendorTaxes({ vendorId });
  const defaultTaxAmount = defaultTax || merchant.taxPercent || 0;
  const taxOptions = useMemo<InputOption[]>(() => {
    if (options.length > 0) {
      return options;
    }

    const vendorTaxOptions =
      taxes?.map((tax) => ({
        label: `${tax.value} % `,
        value: tax.value,
        description: tax.name,
      })) || [];

    vendorTaxOptions.unshift.apply(vendorTaxOptions, [
      {
        label: `0 %`,
        value: 0,
        description: 'No Tax',
      },
      {
        label: `${defaultTaxAmount} % `,
        value: defaultTaxAmount,
        description: 'Default Tax',
      },
    ]);
    return vendorTaxOptions;
  }, [defaultTaxAmount, options, taxes]);
  return (
    <InputComboBox<T>
      freeSolo
      label="Location tax"
      onCreateOption={createTaxOption}
      options={taxOptions}
      inputProps={{
        placeholder: 'Select tax for this location...',
      }}
      {...props}
    />
  );
}
