import { styled } from '@goodfynd/react-web.theme';
import { FormHelperText } from '@mui/material';

export const StyledFileContainer = styled('label', {
  backgroundColor: '$Neutral02',
  display: 'grid',
  justifyItems: 'center',
  alignItems: 'center',
  height: 294,
  border: 'dashed 2px $Neutral05',
  borderRadius: 8,
  position: 'relative',
});

export const StyledHelperText = styled(FormHelperText, {
  marginLeft: '14px !important',
});

export const StyledImage = styled('img', {
  position: 'absolute',
  width: '100%',
  height: '100%',
});
