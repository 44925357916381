import { useMemo } from 'react';
import { getNavigation } from '@config/routes-v2';
import { useApp } from '@context/AppContext';
import { useOrganization } from '@context/OrganizationContext';
import { Navigation as ReusableNavigation } from '@goodfynd/react-web.layout.navigation';
import { useRouter } from 'next/router';

import { useSidebar } from './SidebarContext';

export const Navigation = () => {
  const { asPath } = useRouter();
  const { userIsLoading, user } = useApp();
  const { organization, isLoading } = useOrganization();
  const pageIsLoading = useMemo(
    () => isLoading || userIsLoading,
    [isLoading, userIsLoading]
  );

  const { isCollapsed } = useSidebar();

  return (
    <ReusableNavigation
      isCollapsed={isCollapsed}
      isSelected={({ href }) => !!href && asPath.startsWith(href)}
      items={getNavigation({
        disabled: pageIsLoading,
        orgNameId: organization.nameId,
        roles: user.roles,
      })}
    />
  );
};
