import React, { useEffect, useState } from 'react';
import { useAsync } from '@goodfynd/react-web.hooks.use-async';
import { Loading, Text } from '@goodfynd/react-web.lib.ui';

import { useApp, useAppDispatch } from '../../../context/AppContext';
import { useVendorApi } from '../../../hooks/api';

import type { VendorNameFetchProps } from './types';

/**
 * Fetches vendor names from API by ID
 * Fetched vendor names are stored in memory for the current session
 * and can be used throughout the application
 * @param id Vendor ID to lookup
 * @returns Vendor unique name or 'Unknown Vendor'
 */
export default function VendorNameFetch({
  id,
  ...props
}: VendorNameFetchProps): JSX.Element {
  const api = useVendorApi();
  const { vendorNamesById } = useApp();
  const { setVendorNamesById } = useAppDispatch();
  const { busy, execute } = useAsync();
  const [name, setName] = useState(vendorNamesById[id] || 'Unknown Vendor');

  useEffect(() => {
    if (id && !vendorNamesById[id]) {
      execute(async () => {
        const { vendor } = await api.getPublic(id);
        setName(vendor.uniqueName);
        setVendorNamesById((curValue) => ({
          ...curValue,
          [id]: vendor.uniqueName,
        }));
      });
    }
  }, [api, execute, id, setVendorNamesById, vendorNamesById]);

  return (
    <Text tag={busy ? 'div' : undefined} {...props}>
      {busy ? (
        <>
          Fetching vendor name...
          <Loading type="line" />
        </>
      ) : (
        name
      )}
    </Text>
  );
}
