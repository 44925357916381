import { observer } from 'mobx-react';
import { createContext, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getGeocode } from 'use-places-autocomplete';
import { vendorUtil } from 'utils/index';

import { organizationKeys, vendorKeys } from '@config/queryKeys';
import { mapGeoResult } from '@utils/geo-util';

import { useProfileApi, useVendorApi } from '../../hooks/api';
import { useMerchant } from '../../hooks/useMerchant';
import { useApp } from '../AppContext';
import { useContextVendorId } from './useContextVendorId';

import type { OrganizationProfile } from '../../types/organizationProfile';
import type { VendorProfile } from '../../types/vendorProfile';
import type {
  VendorContextValue,
  VendorDispatchContextValue,
  VendorProviderProps,
} from './types';
export const VendorContext = createContext<VendorContextValue | undefined>(
  undefined
);
export const VendorDispatchContext = createContext<
  VendorDispatchContextValue | undefined
>(undefined);

export default observer(function VendorProvider({
  children,
  vendorId: vendorIdOverride,
}: VendorProviderProps) {
  const { isLoggedIn, user } = useApp();
  const queryClient = useQueryClient();

  const vendorId = useContextVendorId(vendorIdOverride);

  // Fetch merchant profile info
  const {
    invalidate: invalidateMerchant,
    isLoading: merchantIsLoading,
    merchant,
    refetch: refetchMerchant,
  } = useMerchant({
    enabled: isLoggedIn !== false,
    vendorId,
  });

  // Fetch vendor profile info
  const api = useProfileApi();

  const {
    data: vendor = {} as VendorProfile,
    isLoading,
    isFetched,
    isFetching,
    refetch: refetchVendor,
  } = useQuery<VendorProfile, unknown, VendorProfile>(
    vendorKeys.profile(vendorId),
    () => {
      return api.vendorProfile(vendorId as string);
    },
    {
      enabled: isLoggedIn == true && !!vendorId,
    }
  );
  const { data: vendorLocation, isLoading: vendorLocationIsLoading } = useQuery(
    vendorKeys.location(vendorId),
    () => {
      return getGeocode({ address: vendor.address });
    },
    {
      enabled: !!vendor.address,
      select: (result) => {
        return result.length > 0 ? mapGeoResult(result[0]) : undefined;
      },
    }
  );

  const {
    data: vendorOrganization = {} as OrganizationProfile,
    isLoading: vendorOrgIsLoading,
  } = useQuery<OrganizationProfile, unknown>(
    organizationKeys.profile(vendor.organizationId),
    () => {
      return api.organizationProfile(vendor.organizationId);
    },
    {
      enabled: !!vendor.organizationId,
    }
  );

  const invalidateVendor = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: vendorKeys.profile(vendorId),
    });
  }, [queryClient, vendorId]);

  const vendorApi = useVendorApi();
  const toggleVendor = useCallback(
    async (vendor: VendorProfile, visible: boolean) => {
      await vendorApi.toggleVisibility(vendor.id, visible);
      refetchVendor();
    },
    [vendorApi, refetchVendor]
  );

  const launchStripeDashboard = useCallback(async () => {
    try {
      const { url } = await vendorApi.getStripeDashboard(vendorId);
      window.open(url);
    } catch (error) {
      console.error(error);
    }
  }, [vendorApi, vendorId]);

  const isOwner = useMemo(() => {
    const permission = user?.permissions?.find(
      (permission) =>
        // permission.entityType === 'Vendor' &&
        permission.entityId === vendorId || permission.entityNameId === vendorId
    );
    return permission?.canRead || false;
  }, [user?.permissions, vendorId]);

  const value = useMemo<VendorContextValue>(
    () => ({
      isFetched,
      isFetching,
      isLoading: isLoading || vendorOrgIsLoading,
      isOwner,
      merchant,
      merchantIsLoading,
      vendorLocationIsLoading,
      profileId: vendor?.nameId,
      vendor: (vendor || {}) as VendorProfile,
      vendorId: vendor?.nameId,
      vendorOrganization,
      vendorLocation,
    }),
    [
      isFetched,
      isFetching,
      vendorLocationIsLoading,
      isLoading,
      isOwner,
      merchant,
      merchantIsLoading,
      vendor,
      vendorOrgIsLoading,
      vendorOrganization,
      vendorLocation,
    ]
  );

  const dispatchValue = useMemo<VendorDispatchContextValue>(
    () => ({
      invalidateMerchant,
      invalidateVendor,
      isCurrentVendor: (entity) =>
        vendorUtil.idEquals(vendor?.nameId || vendorId, entity),
      launchStripeDashboard,
      refetchMerchant,
      refetchVendor,
      toggleVendor,
    }),
    [
      invalidateMerchant,
      invalidateVendor,
      launchStripeDashboard,
      refetchMerchant,
      refetchVendor,
      toggleVendor,
      vendor?.nameId,
      vendorId,
    ]
  );

  return (
    <VendorContext.Provider value={value}>
      <VendorDispatchContext.Provider value={dispatchValue}>
        {children}
      </VendorDispatchContext.Provider>
    </VendorContext.Provider>
  );
});
