import intersection from 'lodash/intersection';
import { UserInfo } from 'stores/models/user';
import { UserRole } from 'types/enums';

export function hasRoles(userRoles: number[], roles: UserRole[]) {
  const containedRoles = intersection(userRoles, roles);
  return containedRoles.length > 0 && !isNaN(containedRoles[0]);
}

export function isRestrictedAdmin(user: UserInfo) {
  return hasRoles(user.roles, [UserRole.Admin, UserRole.SuperAdmin]);
}
