import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useApp } from '@context/AppContext';

export function useContextOrganizationId(orgIdOverride = '') {
  const { user } = useApp();
  const router = useRouter();

  const organizationId = useMemo(
    () =>
      orgIdOverride ||
      ((router.query.organizationId as string) ?? user?.organizationId),
    [orgIdOverride, router.query.organizationId, user?.organizationId]
  );

  return organizationId;
}
