import React from 'react';
import { useApp, useAppDispatch } from '@context/AppContext';
import { UserProfile } from '@goodfynd/react-web.lib.dashboard';

export const DashboardUserProfile = () => {
  const { user } = useApp();
  const { logout } = useAppDispatch();

  return (
    <UserProfile
      user={user}
      options={[
        {
          label: 'Logout',
          onClick: () => logout(),
        },
      ]}
    />
  );
};
