import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { OptionGroupModel } from './option-group-model';

/**
 * Order Request model.
 */
export const OrderRequestModel = types.model('OrderRequest', {
  id: types.maybe(types.string),
  isDonation: false,
  optionGroups: types.array(OptionGroupModel),
  orderId: types.maybe(types.string),
  pickupTime: types.maybe(types.Date),
  quantity: types.optional(types.number, 1),
  requests: types.maybe(types.string),
  scheduleId: types.string,
  sector: 'trucks',
  value: types.number, // price
});

type OrderRequestType = Instance<typeof OrderRequestModel>;
export interface OrderRequest extends OrderRequestType {}
type OrderRequestSnapshotType = SnapshotOut<typeof OrderRequestModel>;
export interface OrderRequestSnapshot extends OrderRequestSnapshotType {}
export const createOrderDefaultModel = () => types.maybe(OrderRequestModel);
