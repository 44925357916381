import React, { useMemo } from 'react';
import { useApp } from '@context/AppContext';
import { PageTitleBanner } from '@goodfynd/react-web.layout.page-title-banner';
import { LoadingTruck } from '@goodfynd/react-web.lib.ui';
import { useRequireAuth } from '@hooks/useRequireAuth';

import { useOrganization } from '../../../context/OrganizationContext';
import Footer from '../Footer';
import Header from '../Header';
import { LoadingFullPage } from '../LoadingFullPage';

import { StyledPageContainer } from './styles';
import type { LayoutWithHeaderProps } from './types';

export function LayoutWithHeader({
  bannerContainerCss,
  children,
  showOrganizationName,
  authRequired,
  allowedRoles,
  ...props
}: LayoutWithHeaderProps): JSX.Element {
  const isLoggedIn = useRequireAuth({ allowedRoles, authRequired });
  const { userIsLoading } = useApp();
  const { isLoading: orgIsLoading, organization } = useOrganization();
  const isLoading = useMemo(
    () => orgIsLoading || userIsLoading,
    [orgIsLoading, userIsLoading]
  );

  const subtitle = useMemo(() => {
    return showOrganizationName ? organization.name : props.bannerSubTitle;
  }, [organization.name, props.bannerSubTitle, showOrganizationName]);

  if (authRequired && !isLoggedIn) {
    return <></>;
  }

  return (
    <StyledPageContainer css={{ paddingTop: 64 }}>
      <Header />
      <>
        {typeof props.bannerTitle === 'string' ? (
          <PageTitleBanner
            containerCss={bannerContainerCss}
            subtitle={subtitle}
            title={props.bannerTitle}
          />
        ) : (
          props.bannerTitle
        )}

        {isLoading ? (
          <LoadingFullPage>
            <LoadingTruck />
          </LoadingFullPage>
        ) : (
          children
        )}
      </>
      <Footer />
    </StyledPageContainer>
  );
}
