import { useMemo } from 'react';
import { UserSnapshot } from 'stores/models/user';
import { UserRole } from 'types/enums';
import * as stringUtil from 'utils/string-util';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import {
  AvailableRolesResponse,
  ProfileCreateRequest,
  ProfileUpdateRequest,
  UserListResponse,
  UserResponse,
} from '../../stores/auth-store/types';

import type { AutocompleteRequest } from '../../types/search';
import type { AutoCompleteUsersResponse } from '../../services/api/types';
import type { UserPermission } from 'types/shared';
export const useUserApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteUsersResponse> {
        try {
          const data: AutoCompleteUsersResponse = await api.get(
            endpoints.accounts.autocomplete,
            { ...request }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async updatePermissions(
        id: string,
        permissions: UserPermission[]
      ): Promise<UserResponse> {
        try {
          const data: UserResponse = await api.patch(
            stringUtil.replace(endpoints.accounts.permissions, { ':id': id }),
            { permissions }
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getProfile(id: string): Promise<UserResponse> {
        try {
          const data: UserResponse = await api.get(
            stringUtil.replace(endpoints.accounts.profile, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
      async createProfile(
        profile: ProfileCreateRequest
      ): Promise<UserResponse> {
        try {
          const data: UserResponse = await api.post(
            endpoints.user.add,
            profile
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
      async updateProfile(
        id: string,
        profile: ProfileUpdateRequest
      ): Promise<UserResponse> {
        try {
          const data: UserResponse = await api.post(
            stringUtil.replace(endpoints.user.base, { ':id': id }),
            profile
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async availableRoles(): Promise<AvailableRolesResponse> {
        try {
          const roles: UserRole[] = await api.get(
            endpoints.user.availableRoles
          );
          return { success: true, roles };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async setDefaultProfile(
        userId: string,
        vendorNameId: string
      ): Promise<ResponseBase> {
        try {
          const data: ResponseBase = await api.patch(
            stringUtil.replaceAll(endpoints.user.defaultVendor, {
              ':userId': userId,
              ':vendorNameId': vendorNameId,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getUsersByOrganization(
        organizationId: string,
        includeHidden = true
      ): Promise<UserListResponse> {
        try {
          const data: UserSnapshot[] = await api.get(endpoints.user.search, {
            includeHidden,
            organizationId,
          });
          return { success: true, users: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getFranchiseUsers(
        organizationId: string,
        includeHidden = true
      ): Promise<UserListResponse> {
        try {
          const data: UserSnapshot[] = await api.get(endpoints.user.franchise, {
            includeHidden,
            organizationId,
          });
          return { success: true, users: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
