import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { MenuSeachRequest } from '../../types/products';

import type { AddUpdateProductRequest } from '../../stores/product-store/types';
import type { AutocompleteRequest } from '../../types/search';
import type { ProductItem, ProductV2, Suggestion } from '../../types/shared';
import type {
  AutoCompleteResponse,
  MenuSearchRepsonse,
  ProductDeleteResponse,
  ProductResponse,
  ProductV2Response,
  VendorProductV2Response,
} from '../../services/api/types';
export const useOrganizationProductApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.products.organization.search,
            request
          );
          return { success: true, results: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<ProductV2Response> {
        try {
          const data: ProductV2 = await api.get(
            stringUtil.replaceAll(endpoints.products.organization.base, {
              ':id': id,
              ':includeSchedule': '',
            }),
            undefined,
            {
              headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
              },
            }
          );
          return { success: true, product: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getProducts(
        organizationId: string
      ): Promise<VendorProductV2Response> {
        try {
          const data: ProductV2[] = await api.get(
            endpoints.products.organization.search,
            {
              organizationId,
              includeHidden: true,
            }
          );
          return { success: true, products: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getVendorProducts(
        vendorId: string
      ): Promise<VendorProductV2Response> {
        try {
          const data: ProductV2[] = await api.get(
            stringUtil.replaceAll(endpoints.products.organization.vendor, {
              ':vendorId': vendorId,
            }),
            {
              includeHidden: true,
            }
          );
          return { success: true, products: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async toggleHidden(
        id: string,
        visible: boolean
      ): Promise<ProductResponse> {
        try {
          const data: ProductItem = await api.patch(
            stringUtil.replaceAll(endpoints.products.organization.toggle, {
              ':id': id,
              ':visible': String(visible),
            })
          );
          return { success: true, product: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async add(request: AddUpdateProductRequest): Promise<ProductV2Response> {
        try {
          const data: ProductV2Response = await api.postFile(
            endpoints.products.organization.search,
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async update(
        request: AddUpdateProductRequest
      ): Promise<ProductV2Response> {
        try {
          const data: ProductV2Response = await api.putFile(
            stringUtil.replace(endpoints.products.organization.base, {
              ':id': request.id!,
            }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async delete(id: string): Promise<ProductDeleteResponse> {
        try {
          const data: ProductDeleteResponse = await api.delete(
            stringUtil.replace(endpoints.products.organization.base, {
              ':id': id,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async searchMenu(request: MenuSeachRequest): Promise<MenuSearchRepsonse> {
        try {
          const results: MenuSearchRepsonse = await api.get(
            endpoints.products.organization.menuSearch,
            request
          );
          return { success: true, ...results };
        } catch (error) {
          return Promise.reject({ success: false });
        }
      },

      async updateProductPrice(id: string, price: number) {
        try {
          const results: ProductItem = await api.patch(
            stringUtil.replace(endpoints.products.price, { ':id': id }),
            { price }
          );
          return { success: true, ...results };
        } catch (error) {
          return Promise.reject({ success: false });
        }
      },
    };
  }, [api]);
};
