import { Calendar } from 'react-multi-date-picker';

import { DefaultColors } from '../../theme';
import { styled } from '../../theme/stitches.config';

export const StyledCalendar = styled(Calendar, {
  '&.rmdp-wrapper': {
    backgroundColor: '$White',
    padding: '16px 0',
    '&.rmdp-shadow': {
      boxShadow: 'none',
    },
    '.rmdp-calendar, .rmdp-day-picker': {
      borderRight: 'none',
      marginRight: 8,
      padding: 0,
    },
    '.rmdp-arrow-container': {
      height: 32,
      width: 32,
      '&:not(.disabled)': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '$GreenLightest',
          boxShadow: 'none',
        },
        '.rmdp-arrow': {
          borderColor: '$Neutral13',
        },
      },
      '.rmdp-arrow': {
        height: 12,
        marginTop: 10,
        width: 12,
      },
    },
    '.rmdp-header, .rmdp-panel-header': {
      margin: 0,
    },
    '.rmdp-header-values, .rmdp-panel-header': {
      color: '$Neutral13',
      fontSizeRem: 18,
      fontWeight: 400,
      lineHeightRem: 24,
    },
    '.rmdp-week-day, .rmdp-day': {
      fontWeight: 400,
      height: 48,
      width: 48,
    },
    '.rmdp-week-day, .rmdp-day:not(.rmdp-deactive)': {
      color: '$Neutral13',
    },
    '.rmdp-week-day': {
      fontSizeRem: 14,
      lineHeightRem: 21,
    },
    '.rmdp-day': {
      '&.rmdp-deactive': {
        color: '$Neutral07',
      },
      '&.rmdp-disabled': {
        color: '$Neutral05',
      },
      '&.rmdp-today:not(.rmdp-selected)': {
        span: {
          backgroundColor: 'transparent',
          border: '2px solid $Neutral05',
          color: '$Neutral13',
        },
      },
      span: {
        fontSizeRem: 16,
        lineHeightRem: 24,
      },
    },
    '.rmdp-day.rmdp-selected span:not(.highlight)': {
      // selected day
      backgroundColor: '$GreenBrand !important',
      boxShadow: 'none',
      color: '$White !important',
    },
    '.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
      // unselected day hover
      backgroundColor: '$GreenLightest',
      color: DefaultColors.Neutral13,
    },
    '.rmdp-panel': {
      minWidth: 164,
      '@smMax': {
        display: 'none !important',
      },
      '.rmdp-panel-body': {
        li: {
          backgroundColor: '$Neutral02',
          color: '$Neutral13',
          button: {
            backgroundColor: '$GreenBrand',
            color: '$White',
            top: '49%',
          },
          span: {
            cursor: 'initial !important',
          },
        },
        span: {
          fontSizeRem: 14,
          lineHeightRem: 21,
          textAlign: 'right',
          width: 'calc(100% - 28px)',
        },
      },
    },
  },
  '&.react-calendar': {
    border: 'none',
    '.react-calendar__navigation__label__labelText': {
      fontSizeRem: 18,
      fontWeight: 400,
      lineHeightRem: 24,
    },
    '.react-calendar__month-view__weekdays__weekday': {
      fontSizeRem: 14,
      fontWeight: 400,
      lineHeightRem: 21,
      abbr: {
        textDecoration: 'none !important',
      },
    },
    '.react-calendar__tile': {
      fontSizeRem: 16,
      fontWeight: 400,
      lineHeightRem: 24,
      padding: 0,
      abbr: {
        alignItems: 'center',
        borderRadius: '50%',
        display: 'flex',
        // flex: '0 auto !important',
        height: 32,
        justifyContent: 'center',
        margin: '0 auto',
        width: 32,
      },
      '&:enabled:focus': {
        backgroundColor: 'transparent',
      },
      '&:enabled:hover': {
        backgroundColor: 'transparent',
        abbr: {
          backgroundColor: '$GreenLightest',
        },
      },
    },
    '.react-calendar__tile--active': {
      backgroundColor: 'transparent',
      abbr: {
        backgroundColor: '$GreenBrand !important',
      },
    },
    '.react-calendar__month-view__days__day--neighboringMonth': {
      color: '$Neutral05',
    },
    '.react-calendar__tile--now': {
      backgroundColor: 'transparent',
      abbr: {
        border: '2px solid $Neutral05',
      },
    },
  },
});
