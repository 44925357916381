import type { NavigationItemProps } from '@goodfynd/react-web.layout.navigation';
import type { IconName } from '@goodfynd/react-web.lib.ui';

import type { UserRole } from '../types/enums';
import { getOrganizationRoutePath } from '../utils/routes-util';

export const routes = {
  home: '/beta',
  organization: {
    billing: {
      invoices: '/beta/organization/:orgId/events/invoices',
      quotes: '/beta/organization/:orgId/events/quotes',
    },
    catalog: {
      categories: '/beta/organization/:orgId/catalog/categories',
      combos: '/beta/organization/:orgId/catalog/combos',
      menus: '/beta/organization/:orgId/catalog/menus',
      modifiers: '/beta/organization/:orgId/catalog/modifiers',
      products: '/beta/organization/:orgId/catalog/products',
      versions: '/beta/organization/:orgId/catalog/versions',
    },
    events: {
      find: '/beta/organization/:orgId/events/find',
      interested: '/beta/organization/:orgId/events/interested',
      leads: '/beta/organization/:orgId/events/leads',
      scheduled: '/beta/organization/:orgId/events/scheduled',
    },
    loyalty: {
      customers: '/beta/organization/:orgId/loyalty/customers',
      promo: '/beta/organization/:orgId/loyalty/promo',
    },
    marketing: {
      embeds: '/beta/organization/:orgId/marketing/embeds',
      flyers: '/beta/organization/:orgId/marketing/flyers',
      social: '/beta/organization/:orgId/marketing/social',
    },
    orders: {
      home: '/beta/organization/:orgId/orders',
    },
    overview: {
      home: '/beta/organization/:orgId/overview',
    },
    reports: {
      items: '/beta/organization/:orgId/items',
      payouts: '/beta/organization/:orgId/payouts',
      summary: '/beta/organization/:orgId/summary',
      transactions: '/beta/organization/:orgId/transactions',
    },
    settings: {
      account: '/beta/organization/:orgId/settings/account',
      devices: '/beta/organization/:orgId/settings/devices',
      documents: '/beta/organization/:orgId/settings/documents',
      ordering: '/beta/organization/:orgId/settings/online-ordering',
      pos: '/beta/organization/:orgId/settings/pos',
      tax: '/beta/organization/:orgId/settings/tax',
    },
    structure: {
      organizations: '/beta/organization/:orgId/structure/beta/organizations',
      staff: '/beta/organization/:orgId/structure/staff',
      territories: '/beta/organization/:orgId/structure/territories',
      vendors: '/beta/organization/:orgId/structure/vendors',
    },
  },
};

interface NavItemOptions {
  disabled?: boolean;
  orgNameId: string;
  icon?: IconName;
  label: string;
  route?: string;
  subItems?: Omit<NavItemOptions, 'orgNameId'>[];
}
const createNavigationItem = ({
  disabled,
  orgNameId,
  icon,
  label,
  route,
  subItems,
}: NavItemOptions): NavigationItemProps => {
  const props: NavigationItemProps = {
    href:
      route && !disabled
        ? getOrganizationRoutePath(route, orgNameId)
        : undefined,
    icon,
    label,
  };

  if (subItems?.[0]) {
    props.subItems = disabled
      ? undefined
      : subItems?.map((item) => createNavigationItem({ orgNameId, ...item }));
  }

  return props;
};

interface NavigationOptions {
  disabled?: boolean;
  orgNameId: string;
  roles?: UserRole[];
}
export const getNavigation = ({
  disabled,
  orgNameId,
  roles = [],
}: NavigationOptions): NavigationItemProps[] => [
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'home',
    label: 'Overview',
    route: routes.organization.overview.home,
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'organization',
    label: 'Structure',
    subItems: [
      {
        route: routes.organization.structure.organizations,
        label: 'Organizations',
      },
      {
        route: routes.organization.structure.territories,
        label: 'Territories',
      },
      {
        route: routes.organization.structure.vendors,
        label: 'Vendors',
      },
      {
        route: routes.organization.structure.staff,
        label: 'Staff',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'list',
    label: 'Catalog',
    subItems: [
      {
        route: routes.organization.catalog.products,
        label: 'Products',
      },
      {
        route: routes.organization.catalog.modifiers,
        label: 'Modifiers',
      },
      {
        route: routes.organization.catalog.categories,
        label: 'Categories',
      },
      {
        route: routes.organization.catalog.menus,
        label: 'Menus',
      },
      {
        route: routes.organization.catalog.combos,
        label: 'Combos',
      },
      {
        route: routes.organization.catalog.versions,
        label: 'Versions',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'calendar',
    label: 'Events',
    subItems: [
      {
        route: routes.organization.events.scheduled,
        label: 'Scheduled',
      },
      {
        route: routes.organization.events.interested,
        label: 'Interested',
      },
      {
        route: routes.organization.events.find,
        label: 'Find events',
      },
      {
        route: routes.organization.events.leads,
        label: 'Leads',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'order',
    label: 'Orders',
    route: routes.organization.orders.home,
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'invoice',
    label: 'Billing',
    subItems: [
      {
        route: routes.organization.billing.quotes,
        label: 'Qutoes',
      },
      {
        route: routes.organization.billing.invoices,
        label: 'Invoices',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'marketing',
    label: 'Marketing',
    subItems: [
      {
        route: routes.organization.marketing.flyers,
        label: 'Flyers',
      },
      {
        route: routes.organization.marketing.social,
        label: 'Social media',
      },
      {
        route: routes.organization.marketing.embeds,
        label: 'Embeddable features',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'loyalty',
    label: 'Loyalty',
    subItems: [
      {
        route: routes.organization.loyalty.customers,
        label: 'Customers',
      },
      {
        route: routes.organization.loyalty.promo,
        label: 'Promo codes',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'reports',
    label: 'Reports',
    subItems: [
      {
        route: routes.organization.reports.transactions,
        label: 'Transactions',
      },
      {
        route: routes.organization.reports.items,
        label: 'Items',
      },
      {
        route: routes.organization.reports.summary,
        label: 'Summary',
      },
      {
        route: routes.organization.reports.payouts,
        label: 'Payouts',
      },
    ],
  }),
  createNavigationItem({
    disabled,
    orgNameId,
    icon: 'settings',
    label: 'Settings',
    subItems: [
      {
        route: routes.organization.settings.account,
        label: 'Account',
      },
      {
        route: routes.organization.settings.ordering,
        label: 'Online ordering',
      },
      {
        route: routes.organization.settings.pos,
        label: 'Point of sale',
      },
      {
        route: routes.organization.settings.documents,
        label: 'Licenses and permits',
      },
      {
        route: routes.organization.settings.tax,
        label: 'Tax locations',
      },
      {
        route: routes.organization.settings.devices,
        label: 'Devices',
      },
    ],
  }),
];
