import React from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { StyledContainer } from '../styles';

import { InputFileUploadComponent } from './InputFileUploadComponent';
import type { InputFileUploadProps } from './types';

export default function InputFileUpload<T extends FieldValues = FieldValues>({
  css,
  control,
  helperText,
  label,
  name,
  accept,
  crop,
  renderPreview,
  ...props
}: Omit<InputFileUploadProps, 'setValue'>) {
  return (
    <StyledContainer css={css} {...props}>
      <Controller<T>
        control={control}
        name={name as UseControllerProps<T>['name']}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <InputFileUploadComponent<T>
            accept={accept}
            crop={crop}
            errorMessage={error?.message}
            helperText={helperText}
            label={label}
            onChange={onChange}
            renderPreview={renderPreview}
            value={value}
          />
        )}
      />
    </StyledContainer>
  );
}
