import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import {
  ProfileUpdateRequest,
  UserInfoResponse,
} from '../../stores/auth-store/types';

export const useAccountApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async createProfile(
        profile: ProfileUpdateRequest
      ): Promise<UserInfoResponse> {
        try {
          const data: any = await api.postFile(
            endpoints.accounts.create,
            profile
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async updateProfile(
        profile: ProfileUpdateRequest
      ): Promise<UserInfoResponse> {
        try {
          const data: any = await api.putFile(
            endpoints.accounts.update,
            profile
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async userInfo(): Promise<UserInfoResponse> {
        try {
          return await api.get(endpoints.accounts.userInfo);
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
