import React, { useCallback } from 'react';
import { ProfileTile } from '@goodfynd/react-web.dashboard.profile-switcher';
import type { ProfileTileProps } from '@goodfynd/react-web.lib.dashboard';
import { stringUtil } from '@goodfynd/react-web.lib.utils';
import { Autocomplete } from '@goodfynd/react-web.ui.auto-complete';
import { Text } from '@goodfynd/react-web.ui.text';
import useOrganizationAccess from '@hooks/useOrganizationAccess';
import useVendorOrganizationAccess from '@hooks/useVendorOrganizationAccess';

import { useOrganizationApi } from '../../../hooks/api';
import { getVendorSuggestionStatus } from '../AutocompleteVendors/helpers';

import type { AutocompleteRequest } from './../../../types/search';
import type {
  AutocompleteVendorOrganizationsProps,
  OrganizationFilterOption,
} from './types';
export function AutocompleteVendorOrganizations({
  idPropertyName = 'nameId',
  ...props
}: AutocompleteVendorOrganizationsProps) {
  const { hasAccess } = useOrganizationAccess();
  const { hasAccess: hasAccessFromVendor } = useVendorOrganizationAccess();

  const api = useOrganizationApi();

  const getOptions = useCallback(
    async (request: AutocompleteRequest) => {
      try {
        const { organizations, vendors } = await api.autocompleteVendors(
          request
        );

        const vendorOptions: OrganizationFilterOption[] = vendors.map(
          (result) => ({
            description: result.nameId,
            icon: 'truck',
            id: result.id,
            label: result.uniqueName || result.name,
            nameId: result.nameId,
            type: 'vendor',
            value: result[idPropertyName] || result.id,
            status: getVendorSuggestionStatus(result),
          })
        );

        const organizationOptions: OrganizationFilterOption[] = (
          hasAccess || hasAccessFromVendor ? organizations : []
        ).map((result) => ({
          description: 'Organization',
          icon: 'organization',
          id: result.id,
          label: result.uniqueName || result.name,
          nameId: result.nameId,
          type: 'organization',
          value: result[idPropertyName] || result.id,
          status: 'organization',
        }));

        const results: OrganizationFilterOption[] = [
          ...vendorOptions,
          ...organizationOptions,
        ];

        return results;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    [api, hasAccess, hasAccessFromVendor, idPropertyName]
  );

  return (
    <Autocomplete<OrganizationFilterOption>
      getOptions={getOptions}
      label="Search"
      placeholder="Find vendors and organizations..."
      groupBy={(option) =>
        stringUtil.pluralize(stringUtil.titleCase(option.type ?? ''), 2)
      }
      renderGroup={(params) => (
        <li key={params.group}>
          <Text as="subhead2" style={{ marginLeft: 16, padding: 10 }}>
            {params.group}
          </Text>
          <div>{params.children}</div>
        </li>
      )}
      renderOption={(optionProps, option) => (
        <ProfileTile
          {...optionProps}
          key={option.id}
          label={option.label}
          status={option.status}
          type={option.type as ProfileTileProps['type']}
        />
      )}
      {...props}
    />
  );
}
