import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import { PayoutInterval } from '../../types/enums';

import type { MerchantItem } from '@goodfynd/react-web.types';

import type { SearchRequest } from '../../types/search';
import type {
  MerchantResponse,
  PayoutTransactionsResponse,
  PayoutsResponse,
} from '../../services/api/types';
export const usePayoutsApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async connectStripe(
        vendorId: string,
        request: MerchantItem
      ): Promise<MerchantResponse> {
        try {
          const data: MerchantResponse = await api.post(
            stringUtil.replace(endpoints.payouts.stripe.connect, {
              ':id': vendorId,
            }),
            request
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(query: SearchRequest): Promise<PayoutsResponse> {
        try {
          const data: PayoutsResponse = await api.get(
            endpoints.payouts.search,
            {
              rows: 25,
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getTransactions(
        payoutId: string,
        query: SearchRequest
      ): Promise<PayoutTransactionsResponse> {
        try {
          const data: PayoutTransactionsResponse = await api.get(
            stringUtil.replaceAll(endpoints.payouts.report, {
              ':id': payoutId,
            }),
            {
              rows: 25,
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async requestInstantPayout(
        vendorId: string,
        amount: number
      ): Promise<ResponseBase> {
        try {
          const data: ResponseBase = await api.post(
            stringUtil.replaceAll(endpoints.payouts.instant, {
              ':vendorId': vendorId,
            }),
            {
              amount: amount.toString(),
            }
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async updatePayoutSchedule(
        vendorId: string,
        intervalId: PayoutInterval
      ): Promise<MerchantResponse> {
        try {
          const data: MerchantResponse = await api.put(
            stringUtil.replaceAll(endpoints.payouts.schedule, {
              ':vendorId': vendorId,
              ':intervalId': intervalId,
            })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
