import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import type {
  AutoCompleteResponse,
  CategoriesResponse,
  CreateVendorResponse,
  MerchantResponse,
  PublicVendorResponse,
  UrlResponse,
  VendorAgreementResponse,
  VendorEventsRepsonse,
  VendorFrequentLocationsResponse,
  VendorLicenseResponse,
  VendorOrderingSettingsRequest,
  VendorOrganizationResponse,
  VendorProductsResponse,
  VendorResponse,
  VendorScheduleSearchResponse,
  VendorSearchResponse,
  VendorSearchResponseLegacy,
  VendorSettingsResponse,
  VendorTaxResponse,
  VendorToggleResponse,
} from '../../services/api/types';
import useFetch from '../../services/api/useFetch';
import type { AddUpdateVendorRequest } from '../../stores/vendor-store/types';
import type { AutocompleteRequest, SearchRequest } from '../../types/search';
import type { CategoryItem, Suggestion, VendorItem } from '../../types/shared';
import type { VendorBusinessInfo, VendorCustomer } from '../../types/vendors';
import type { VendorSummary } from '../../types/vendorSummary';

export const useVendorApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.vendors.autocomplete,
            request
          );
          return { success: true, results: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<VendorResponse> {
        try {
          const data: VendorResponse = await api.get(
            stringUtil.replace(endpoints.vendors.base, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getAgreements(id: string): Promise<VendorAgreementResponse> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.agreements, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getBusinessInfo(id: string): Promise<VendorBusinessInfo> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.businessInfo, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getFrequentLocations(
        id: string
      ): Promise<VendorFrequentLocationsResponse> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.frequentLocations, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getPublic(id: string): Promise<PublicVendorResponse> {
        try {
          const data: PublicVendorResponse = await api.get(
            stringUtil.replace(endpoints.vendors.public, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getCategories(vendorId: string): Promise<CategoriesResponse> {
        try {
          const data: CategoryItem[] = await api.get(
            endpoints.categories.search,
            {
              includeHidden: true,
              vendorId,
            }
          );
          return { success: true, categories: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getCustomer(
        vendorId: string,
        customerId: string
      ): Promise<VendorCustomer> {
        try {
          const data: VendorCustomer = await api.get(
            stringUtil.replaceAll(endpoints.vendors.customer, {
              ':id': customerId,
              ':vendorId': vendorId,
            })
          );
          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getMerchant(id: string): Promise<MerchantResponse> {
        try {
          const data: MerchantResponse = await api.get(
            stringUtil.replace(endpoints.orders.merchants, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: any) {
          throw { success: false, ...error };
        }
      },

      async getOpenVendors(
        query: SearchRequest
      ): Promise<VendorScheduleSearchResponse> {
        try {
          const data: VendorScheduleSearchResponse = await api.get(
            endpoints.vendors.orderingSchedules,
            {
              rows: 48,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );

          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getOrderingSchedules(date = ''): Promise<VendorSearchResponse> {
        try {
          const data: VendorSearchResponseLegacy = await api.get(
            stringUtil.replace(endpoints.vendors.orderingSchedules, {
              ':date': date,
            })
          );
          const results: VendorItem[] = ([] as VendorItem[]).concat.apply(
            [],
            data.schedules.map((schedule) => schedule.vendors)
          );
          return { success: true, ...data, results };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getOrganizationPath(
        id: string
      ): Promise<VendorOrganizationResponse> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.organizationPath, {
              ':id': id,
            })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getProducts(vendorId: string): Promise<VendorProductsResponse> {
        try {
          const data: VendorProductsResponse = await api.get(
            endpoints.vendors.products,
            {
              ignoreLocation: true,
              sector: 'trucks',
              vendor: vendorId,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getStripeDashboard(vendorId: string): Promise<UrlResponse> {
        try {
          const data: UrlResponse = await api.get(
            stringUtil.replace(endpoints.vendors.stripeDashboard, {
              ':id': vendorId,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async addVendor(vendor: AddUpdateVendorRequest) {
        try {
          const data: CreateVendorResponse = await api.postFile(
            endpoints.vendors.add,
            vendor
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async updateVendor(vendor: AddUpdateVendorRequest) {
        try {
          const data: VendorResponse = await api.putFile(
            stringUtil.replace(endpoints.vendors.base, { ':id': vendor.id! }),
            vendor
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getSettings(): Promise<VendorSettingsResponse> {
        try {
          const data: VendorSettingsResponse = await api.get(
            endpoints.vendors.settings
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getEvents(
        vendorId: string,
        type: 'interested' | 'confirmed' | 'awaiting-confirmation'
      ) {
        let endpoint: string;
        try {
          switch (type) {
            case 'interested':
              endpoint = endpoints.vendors.events.interested;
              break;
            case 'confirmed':
              endpoint = endpoints.vendors.events.confirmed;
              break;
            case 'awaiting-confirmation':
              endpoint = endpoints.vendors.events.awaitingConfirmation;
              break;
          }

          const data: VendorEventsRepsonse = await api.get(
            stringUtil.replace(endpoint, {
              ':id': vendorId,
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async toggleVisibility(
        id: string,
        visible: boolean
      ): Promise<VendorToggleResponse> {
        try {
          const data: VendorToggleResponse = await api.patch(
            stringUtil.replaceAll(endpoints.vendors.toggle, {
              ':id': id,
              ':visible': String(visible),
            })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async updateOrderingSettings(
        id: string,
        request: VendorOrderingSettingsRequest
      ) {
        try {
          return await api.put(
            stringUtil.replace(endpoints.vendors.orderingSettings, {
              ':id': id,
            }),
            request
          );
        } catch (error: unknown) {
          return Promise.reject({ error });
        }
      },

      async getVendorsInOrganization(organizationId: string) {
        try {
          const data: Array<VendorItem> = await api.get(
            stringUtil.replace(endpoints.vendors.getVendorsInOrganization, {
              ':id': organizationId,
            })
          );
          return data;
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getLicenses(id: string): Promise<VendorLicenseResponse> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.licenses, { ':id': id })
          );
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getMap(id: string): Promise<ItemMap> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.map, { ':id': id })
          );
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async getSummary(id: string): Promise<VendorSummary> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.summary, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getTaxes(id: string): Promise<VendorTaxResponse> {
        try {
          return await api.get(
            stringUtil.replace(endpoints.vendors.taxes, { ':id': id })
          );
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
