export enum CommissionStructure {
  Consumer = 'consumer',
  Vendor = 'vendor',
}

export enum PayoutInterval {
  Daily = '1',
  Weekly = '2',
  Monthly = '3',
}
export enum UserRole {
  Admin = 0,
  VendorOwner = 1,
  User = 2,
  LotOwner = 3,
  SuperAdmin = 4,
  Intern = 5,
  VendorManager = 6,
  VendorEmployee = 7,
  OrganizationOwner = 8,
  OrganizationManager = 9,
  OrganizationAccountant = 10,
}

export const DashboardRoles = [
  UserRole.Admin,
  UserRole.Intern,
  UserRole.OrganizationOwner,
  UserRole.OrganizationManager,
  UserRole.OrganizationAccountant,
  UserRole.VendorEmployee,
  UserRole.VendorManager,
  UserRole.VendorOwner,
  UserRole.SuperAdmin,
];
