import React from 'react';

import { StyledContainer, StyledText } from './styles';
import type { BadgeProps } from './types';

export default function Badge({ children, textStyle, ...props }: BadgeProps) {
  return (
    <StyledContainer
      {...props}
      css={{
        cursor: props.onClick && 'pointer',
        ...props.css,
      }}
    >
      {typeof children === 'string' || typeof children === 'number' ? (
        <StyledText css={textStyle} type="subh3">
          {children}
        </StyledText>
      ) : (
        children
      )}
    </StyledContainer>
  );
}
