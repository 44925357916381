import React, { useMemo } from 'react';
import AuthFallback from '@components/auth/AuthFallback';
import { OrganizationSwitcher } from '@components/organizations/OrganizationSwitcher';
import routes from '@config/routes';
import { useApp } from '@context/AppContext';
import { useOrganization } from '@context/OrganizationContext';
import {
  Divider,
  IconButton,
  LoadingTruck,
  Logo,
  Text,
} from '@goodfynd/react-web.lib.ui';
import { useScreenPhone } from '@goodfynd/react-web.theme';
import { FlexView } from '@goodfynd/react-web.ui.layout';
import { useLocalStorageState } from '@hooks/useLocalStorageState';
import { useRequireAuth } from '@hooks/useRequireAuth';
import { NoSsr } from '@mui/material';
import { getOrganizationRoutePath } from '@utils/routes-util';
import classnames from 'clsx';
import uniqBy from 'lodash/unionBy';
import Head from 'next/head';
import { useRouter } from 'next/router';

import DashboardHeader from './DashboardHeader';
import { DashboardUserProfile } from './DashboardUserProfile';
import { Sidebar } from './Sidebar';
import { SidebarProvider } from './SidebarContext';
import { StyledSwitcherContainer } from './styles';

import classes from './LayoutDashboard.module.css';

export const LayoutDashboard = ({
  children,
  fullWidth,
  mainClass,
  title,
  rightTitleNode,
}: LayoutDashboardProps) => {
  useRequireAuth();

  const router = useRouter();
  const { isLoggedIn, userIsLoading, user } = useApp();
  const { organization, isLoading } = useOrganization();
  const [previousOrganizations, setPreviousOrganizations] =
    useLocalStorageState<SelectOption<string>[]>('organization-history', []);
  const isPhone = useScreenPhone();

  const hasMultipleOrganizationAccess = useMemo(
    () =>
      !!user &&
      (user.isSuperAdmin ||
        user.permissions?.filter((entry) => entry.entityType === 'Organization')
          .length > 1),
    [user]
  );

  const switcherVariant = useMemo(() => {
    if (hasMultipleOrganizationAccess) {
      return isPhone ? 'collapse' : 'popover';
    }

    return 'display';
  }, [hasMultipleOrganizationAccess, isPhone]);

  const switcher = (
    <OrganizationSwitcher
      recentOptions={previousOrganizations}
      varitant={switcherVariant}
      onChange={(option) => {
        router.push(
          getOrganizationRoutePath(
            routes.beta.organization.overview.home,
            option.value
          )
        );
        setPreviousOrganizations((previous = []) =>
          uniqBy([option, ...previous].slice(0, 5), (option) => option.value)
        );
      }}
      oragnization={{
        label: organization.name,
        value: organization.nameId,
      }}
    />
  );

  if (isLoading || !router.isReady || userIsLoading) {
    return <LoadingTruck />;
  }

  if (!isLoggedIn) {
    return <AuthFallback />;
  }

  return (
    <>
      <Head>
        <meta content="noindex, nofollow" name="robots" />
      </Head>

      <NoSsr>
        <SidebarProvider>
          <div className={classes.page}>
            <Sidebar />

            <div className={classnames(classes.main, mainClass)}>
              <DashboardHeader
                drawerContent={
                  <>
                    <div className={classes['drawer-profile']}>
                      <DashboardUserProfile />
                    </div>

                    <Divider />

                    <StyledSwitcherContainer>
                      {switcher}
                    </StyledSwitcherContainer>

                    <Divider />
                  </>
                }
                rightNode={
                  <IconButton
                    className={classes['header-buttton']}
                    href="/docs"
                    icon="help"
                  />
                }
              >
                {!isPhone ? switcher : <Logo />}
              </DashboardHeader>

              <div className={classes['content-container']}>
                <FlexView
                  align="center"
                  className={classes['content-span']}
                  justify="space-between"
                >
                  <FlexView align="center">
                    {typeof title === 'string' ? (
                      <Text as="pageTitle">{title}</Text>
                    ) : (
                      title
                    )}
                  </FlexView>

                  <FlexView justify="end">{rightTitleNode}</FlexView>
                </FlexView>

                <div
                  className={classnames(classes['content-span'], {
                    [classes['full-width']]: fullWidth,
                  })}
                >
                  <div className={classes.content}>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </SidebarProvider>
      </NoSsr>
    </>
  );
};
