import { styled } from '@goodfynd/react-web.theme';
import TextField from '@mui/material/TextField';

export const StyledMenuItem = styled('div', {
  display: 'flex',
  padding: '8px 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '$GreenLightest !important',
  },
  svg: {
    margin: '0 4px 0 0 !important',
  },
});

export const StyledTextField = styled(TextField, {
  '&.MuiTextField-root': {
    input: {
      paddingLeft: 0,
    },
  },
});

export const StyledTextFieldContainer = styled('aside', {
  padding: 16,
  paddingBottom: 24,
});
