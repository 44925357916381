import React from 'react';
import { useContextVendorId } from '@context/VendorContext/useContextVendorId';
import { SideNav as ReusableSideNav } from '@goodfynd/react-web.layout.side-nav';
import useOrganizationAccess from '@hooks/useOrganizationAccess';
import useVendorOrganizationAccess from '@hooks/useVendorOrganizationAccess';

import config from '../../../config';
import { useApp, useAppDispatch } from '../../../context/AppContext';
import { ProfileSwitcher } from '../../vendors';

import { useVendorNavItems } from './useSideNavLinks';

export const SideNav = function SideNav() {
  const { isLoggedIn } = useApp();
  const { logout } = useAppDispatch();

  const vendorId = useContextVendorId();
  const navItems = useVendorNavItems();
  const { hasAccess } = useOrganizationAccess();
  const { hasAccess: hasAccessFromVendor } = useVendorOrganizationAccess();
  return (
    <ReusableSideNav
      items={navItems}
      logoEnvironment={config.env.NAME_LOWER}
      logoType="vendors"
      header={
        <ProfileSwitcher
          css={{ width: 'fit-content' }}
          profileId={vendorId}
          showOrganizations={hasAccess || hasAccessFromVendor}
        />
      }
      logout={
        isLoggedIn
          ? () => {
              logout();
            }
          : undefined
      }
    />
  );
};
