import { useApp } from '../context/AppContext';
import config from '../config';
import { useMemo } from 'react';
import { useVendor } from '@context/VendorContext';

export default function useVendorOrganizationAccess() {
  const { user, userIsFetched } = useApp();
  const { vendor, isFetched } = useVendor();

  return useMemo(() => {
    return {
      isLoaded: isFetched && userIsFetched,
      hasAccess:
        (isFetched &&
          vendor.subscriptions?.includes(
            config.subscriptions.organizationDashboard
          )) ||
        (userIsFetched && user.isRestrictedAdmin),
    };
  }, [user, userIsFetched, vendor.subscriptions, isFetched]);
}
