import type { ProfileStatus } from '@goodfynd/react-web.lib.dashboard';
import type { Suggestion } from 'types/shared';

export const getVendorSuggestionStatus = (
  suggestion: Suggestion
): ProfileStatus => {
  if (suggestion.isDeleted) {
    return 'no-profiles';
  }

  return suggestion.orderingEnabled ? 'online' : 'offline';
};
