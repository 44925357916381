import { useEffect, useState } from 'react';
import { getGeocode } from 'use-places-autocomplete';

import config from '../config';
import endpoints from '../services/api/endpoints';
import { storageUtil } from '../utils';
import useFetch from './useFetch';

export interface LocationOptions {
  reverseGeocode?: boolean;
  store?: boolean;
}

export default function useIPLocation({
  reverseGeocode,
  store = true,
}: LocationOptions = {}) {
  const { start, ...results } = useFetch<Coordinates>(
    endpoints.locations.byIP,
    {},
    false
  );
  const [address, setAddress] = useState<PlaceAddress>();
  const [busy, toggleBusy] = useState(true);
  const [error, setError] = useState<any>();
  const [position, setPosition] = useState<Coordinates>();

  useEffect(() => {
    (async () => {
      const storedLocation = storageUtil.local.get<ItemLocation>(
        config.storageKeys.userLocation
      );

      if (storedLocation?.placeAddress) {
        setAddress(storedLocation.placeAddress);
        setPosition(storedLocation);
        toggleBusy(false);
        return;
      } else if (storedLocation) {
        handleSuccess(storedLocation);
      } else {
        start();
      }
    })();
  }, []);

  useEffect(() => {
    if (!address && !error && reverseGeocode && results?.data) {
      console.debug('useIP: ', JSON.stringify(results.data));
      handleSuccess(results.data);
    } else if (results?.error) {
      handleError(error);
    }
  }, [results]);

  useEffect(() => {
    if (!busy && reverseGeocode && !address && position) {
      handleSuccess(position);
    }
  }, [reverseGeocode]);

  const handleSuccess = async (coords: Coordinates) => {
    toggleBusy(true);

    const { latitude, longitude } = coords;
    let placeAddress: PlaceAddress | undefined;

    try {
      
      if (reverseGeocode) {
        // TODO: Add geocoding support
        const location = { lat: latitude, lng: longitude };
        const results = await getGeocode({ location });
        if (results?.length > 0) {
          const result = results[0];
          placeAddress = {
            id: result.place_id,
            label: result.formatted_address,
          };
          setAddress(placeAddress);
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setPosition({
        latitude,
        longitude,
      });

      if (store) {
        storageUtil.local.set(config.storageKeys.userLocation, {
          ...coords,
          placeAddress,
          coordinates: `${latitude}, ${longitude}`,
        });
      }

      toggleBusy(false);
    }
  };

  const handleError = (error: any) => {
    console.debug(error);

    setError(error);
    setPosition(undefined);
    storageUtil.local.remove(config.storageKeys.userLocation),
      toggleBusy(false);
  };

  return {
    address,
    busy,
    error,
    handleSuccess,
    position,
    setAddress,
    setPosition,
  };
}
