import { styled } from '@goodfynd/react-web.theme';
import Popover from '@mui/material/Popover';

export const StyledSwitcher = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 4,
});

export const StyledPopover = styled(Popover, {
  '.MuiPopover-paper': {
    padding: 24,
    marginTop: 8,
    borderRadius: 12,
    width: 435,
    height: 436,
  },
});
