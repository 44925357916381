import { useMemo } from 'react';
import { stringUtil } from 'utils/index';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { MenuSeachRequest } from '../../types/products';

import type { AddUpdateProductRequest } from '../../stores/product-store/types';
import type { AutocompleteRequest, SearchRequest } from '../../types/search';
import type { ProductItem, ProductV2, Suggestion } from '../../types/shared';
import type {
  AutoCompleteResponse,
  LikeResponse,
  MenuSearchRepsonse,
  ProductDeleteResponse,
  ProductResponse,
  ProductSearchResponse,
  ProductV2Response,
  VendorProductV2Response,
} from '../../services/api/types';
export const useProductApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async autocomplete(
        request: AutocompleteRequest
      ): Promise<AutoCompleteResponse> {
        try {
          const data: Suggestion[] = await api.get(
            endpoints.products.autocomplete,
            request
          );
          return { success: true, results: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async get(id: string): Promise<ProductV2Response> {
        try {
          const data: ProductV2 = await api.get(
            stringUtil.replaceAll(endpoints.products.base, {
              ':id': id,
            }),
            undefined,
            {
              headers: {
                pragma: 'no-cache',
                'cache-control': 'no-cache',
              },
            }
          );
          return { success: true, product: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async getVendorProducts(
        vendorId: string
      ): Promise<VendorProductV2Response> {
        try {
          const data: ProductV2[] = await api.get(endpoints.vendors.products, {
            vendorId,
            includeHidden: true,
          });
          return { success: true, products: data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async search(query: SearchRequest): Promise<ProductSearchResponse> {
        try {
          const data: ProductSearchResponse = await api.get(
            endpoints.products.search,
            {
              open: false,
              page: 1,
              rows: 24,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return { success: true, ...data };
        } catch (error) {
          return Promise.reject(error);
        }
      },

      async toggleLike(id: string, liked: boolean): Promise<LikeResponse> {
        try {
          const data: LikeResponse = await (liked ? api.delete : api.put)(
            stringUtil.replace(endpoints.products.like, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async toggleHidden(
        id: string,
        visible: boolean
      ): Promise<ProductResponse> {
        try {
          const data: ProductItem = await api.patch(
            stringUtil.replaceAll(endpoints.products.toggle, {
              ':id': id,
              ':visible': String(visible),
            })
          );
          return { success: true, product: data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async add(request: AddUpdateProductRequest): Promise<ProductV2Response> {
        try {
          const data: ProductV2Response = await api.postFile(
            endpoints.products.search,
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async update(
        request: AddUpdateProductRequest
      ): Promise<ProductV2Response> {
        try {
          const data: ProductV2Response = await api.putFile(
            stringUtil.replace(endpoints.products.base, { ':id': request.id! }),
            request
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async delete(id: string): Promise<ProductDeleteResponse> {
        try {
          const data: ProductDeleteResponse = await api.delete(
            stringUtil.replace(endpoints.products.delete, { ':id': id })
          );
          return { success: true, ...data };
        } catch (error: any) {
          return Promise.reject({ success: false, ...error });
        }
      },

      async searchMenu(request: MenuSeachRequest): Promise<MenuSearchRepsonse> {
        try {
          const results: MenuSearchRepsonse = await api.get(
            endpoints.products.menuSearch,
            request
          );
          return { success: true, ...results };
        } catch (error) {
          return Promise.reject({ success: false });
        }
      },

      async updateProductPrice(id: string, price: number) {
        try {
          const results: ProductItem = await api.patch(
            stringUtil.replace(endpoints.products.price, { ':id': id }),
            { price }
          );
          return { success: true, ...results };
        } catch (error) {
          return Promise.reject({ success: false });
        }
      },
    };
  }, [api]);
};
