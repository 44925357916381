import { useMemo } from 'react';

import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';

import type { SearchRequest } from '../../types/search';
import type { OrderSearchRequest } from '../../types/orders';
import type {
  OrderItemSearchResponse,
  OrdersReportCustomerOverviewResponse,
  OrdersReportPopularProductsResponse,
  OrdersReportSummaryResponse,
  OrdersReportTrendResponse,
} from '../../services/api/types';

export type ModifyOrderActions = 'complete' | 'prepare' | 'ready';
export const useOrdersReportApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async customerOverview(
        query: OrderSearchRequest
      ): Promise<OrdersReportCustomerOverviewResponse> {
        try {
          const data: OrdersReportCustomerOverviewResponse = await api.get(
            endpoints.reports.customerOverview,
            {
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async items(query: OrderSearchRequest): Promise<OrderItemSearchResponse> {
        try {
          const data: OrderItemSearchResponse = await api.get(
            endpoints.reports.items,
            {
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async popularProducts(
        query: OrderSearchRequest
      ): Promise<OrdersReportPopularProductsResponse> {
        try {
          const data: OrdersReportPopularProductsResponse = await api.get(
            endpoints.reports.popularProducts,
            {
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async summary(
        query: OrderSearchRequest
      ): Promise<OrdersReportSummaryResponse> {
        try {
          const data: OrdersReportSummaryResponse = await api.get(
            endpoints.reports.summary,
            {
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async trend(
        query: OrderSearchRequest
      ): Promise<OrdersReportTrendResponse> {
        try {
          const data: OrdersReportTrendResponse = await api.get(
            endpoints.reports.trend,
            {
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
