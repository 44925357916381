import { darkTheme, styled } from '@goodfynd/react-web.theme';
import Chip from '@mui/material/Chip';

export const StyledChip = styled(Chip, {
  '&.MuiChip-root': {
    background: '$Neutral02',
    height: 32,
    '& .MuiChip-deleteIcon': {
      color: '$Neutral13',
    },
    '& .MuiChip-label': {
      color: '$Neutral13',
      fontWeight: '800',
    },
  },
  variants: {
    error: {
      true: {
        '&.MuiChip-root': {
          border: ' 1px solid $RedBrand',
          background: '$RedLightest',
        },
      },
    },
    active: {
      true: {
        '&.MuiChip-root': {
          background: '$GreenBrand',
          '&:hover': {
            background: '$GreenDarker',
          },

          '& .MuiChip-deleteIcon': {
            color: '$Neutral13',
            [`.${darkTheme} &`]: {
              color: '$Neutral00',
            },
          },
          '& .MuiChip-label': {
            color: '$Neutral00',
          },
        },
      },
    },
  },
});
