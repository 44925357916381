import React from 'react';
import { useToggle } from '@goodfynd/react-web.lib.hooks';
import { IconButton } from '@goodfynd/react-web.lib.ui';
import { useScreenXLarge } from '@goodfynd/react-web.theme';
import { FlexView } from '@goodfynd/react-web.ui.layout';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import classnames from 'clsx';

import { DashboardUserProfile } from './DashboardUserProfile';
import { Navigation } from './Navigation';
import { StyledDrawerContainer, StyledHeaderLeftContainer } from './styles';

import classes from './LayoutDashboard.module.css';

const DashboardHeader = ({
  children,
  rightNode,
  drawerContent,
}: LayoutDashboardHeaderProps) => {
  const isDesktop = useScreenXLarge();
  const [open, toggle] = useToggle();

  return (
    <>
      <FlexView
        align="center"
        className={classes.header}
        justify="space-between"
        wrap="nowrap"
      >
        <StyledHeaderLeftContainer>{children}</StyledHeaderLeftContainer>

        <div className={classes['header-right']}>
          {rightNode}

          {isDesktop && <DashboardUserProfile />}

          <IconButton
            icon="more"
            onClick={toggle}
            className={classnames(
              classes['header-buttton'],
              classes['drawer-button']
            )}
          />
        </div>
      </FlexView>

      <SwipeableDrawer
        anchor="right"
        onClose={() => toggle(false)}
        onOpen={() => toggle(true)}
        open={open}
      >
        <StyledDrawerContainer>
          {drawerContent}

          <div className={classes['drawer-nav']}>
            <Navigation />
          </div>
        </StyledDrawerContainer>
      </SwipeableDrawer>
    </>
  );
};

export default DashboardHeader;
