import { useMemo } from 'react';

import config from '../../config';
import endpoints from '../../services/api/endpoints';
import useFetch from '../../services/api/useFetch';
import * as dateUtil from '../../utils/date-util';
import * as storageUtil from '../../utils/storage-util';
import * as stringUtil from '../../utils/string-util';

import type { CartOrder } from '@goodfynd/react-web.types';
import type { AutocompleteRequest, SearchRequest } from '../../types/search';
import type { CustomerSuggestion } from '../../types/shared';
import type {
  BasicCustomer,
  CreateInvoiceRequest,
  CreditCard,
  CustomerOrderRequest,
  InvoiceCreditRequest,
  InvoiceRefundRequest,
  MessageCustomerRequest,
  OrderCancelRequest,
  OrderSearchRequest,
  OrdersRequest,
  PaymentCard,
  ReceiptRequest,
  RefundRequest,
  RemoveItemRequest,
  UpdatePickupRequest,
} from '../../types/orders';
import type {
  CartResponse,
  CheckoutCustomerResponse,
  CountResponse,
  CreditCardResponse,
  InvoiceResponse,
  OrderResponse,
  OrderSearchResponse,
  TransactionsResponse,
} from '../../services/api/types';
export type ModifyOrderActions = 'complete' | 'prepare' | 'ready';
export const useOrderApi = () => {
  const api = useFetch();
  return useMemo(() => {
    return {
      async applyPromo(code: string): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.put(
            stringUtil.replace(endpoints.orders.promo, { ':code': code })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async cancel(
        id: string,
        request: OrderCancelRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.orders.cancel, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async capturePayment(vendorId: string, payment: PaymentCard) {
        const paymentFormData = new FormData();
        payment.signature &&
          paymentFormData.append('file', {
            uri: payment.signature,
            name: `${payment.intentId}_sig.png`,
            type: 'image/png',
          } as any);
        (Object.keys(payment) as Array<keyof PaymentCard>).forEach((key) => {
          if (payment[key] instanceof Date) {
            paymentFormData.append(key, (payment[key] as Date).toISOString());
          } else if (
            key !== 'signature' &&
            payment.hasOwnProperty(key) &&
            payment[key]
          ) {
            const value = payment[key]?.toString();
            value && paymentFormData.append(key, value);
          }
        });

        try {
          const data: OrderResponse = await api.postFile(
            stringUtil.replace(endpoints.pos.capture, {
              ':vendorId': vendorId,
            }),
            paymentFormData
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async clearSale(id: string): Promise<ResponseBase> {
        try {
          await api.post(
            stringUtil.replace(endpoints.orders.clear, {
              ':id': id,
            })
          );
          return { success: true };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async count(query: OrderSearchRequest): Promise<CountResponse> {
        try {
          const data: CountResponse = await api.get(
            endpoints.orders.count,
            query
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async organizationCount(
        query: OrderSearchRequest
      ): Promise<CountResponse> {
        try {
          const data: CountResponse = await api.get(
            endpoints.orders.organization.count,
            query
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async credit(
        id: string,
        request: InvoiceCreditRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.invoices.credit, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async finalizeInvoice(id: string, tip: number): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoints.invoices.finalize, {
              ':id': id,
              ':tip': tip.toString(),
            })
          );

          return data;
        } catch (error: any) {
          return Promise.reject(error);
        }
      },

      async find(request: CustomerOrderRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.findCustomer,
            request
          );
          return { success: true, ...data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getDayOrders({
        date,
        ...request
      }: OrdersRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.search,
            {
              ...request,
              dateEnd: date && dateUtil.endOfDay(date).toISOString(),
              dateStart: date && dateUtil.startOfDay(date).toISOString(),
              rows: 480,
              tags: 'complete,in progress',
            }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getOrder(id: string): Promise<CartOrder> {
        try {
          const data: CartOrder = await api.get(
            stringUtil.replace(endpoints.orders.base, {
              ':id': id,
            })
          );

          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getTransactions(ids: string): Promise<TransactionsResponse> {
        try {
          const data: TransactionsResponse = await api.get(
            endpoints.orders.transactions,
            {
              ids,
            }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async modify(
        id: string,
        action: ModifyOrderActions = 'complete',
        transactionId: string | undefined = undefined
      ): Promise<OrderResponse> {
        try {
          let endpoint = '';
          switch (action) {
            case 'prepare':
              endpoint = endpoints.orders.prepare;
              break;

            case 'ready':
              endpoint = endpoints.orders.ready;
              break;

            default:
              endpoint = endpoints.orders.complete;
          }

          const data: OrderResponse = await api.post(
            stringUtil.replaceAll(endpoint, {
              ':id': id,
              ':transactionId': transactionId || '',
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async refund(id: string, request: RefundRequest): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.orders.refund, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async refundInvoice(
        id: string,
        request: InvoiceRefundRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.invoices.refund, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async removeCreditCard(card: CreditCard): Promise<CreditCardResponse> {
        const data: CreditCardResponse = await api.delete(
          endpoints.stripe.card,
          card
        );

        return data;
      },

      async removeItem(
        productId: string,
        request: RemoveItemRequest
      ): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.post(
            stringUtil.replace(endpoints.orders.remove, { ':id': productId }),
            {
              ...request,
              quantity: 0,
              sector: 'trucks',
            }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async search(query: OrderSearchRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(
            endpoints.orders.search,
            {
              page: 1,
              rows: 48,
              sector: 'trucks',
              ...query,
            } as SearchRequest
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async searchSales(query: SearchRequest): Promise<OrderSearchResponse> {
        try {
          const data: OrderSearchResponse = await api.get(endpoints.pos.sales, {
            page: 1,
            rows: 48,
            sector: 'trucks',
            ...query,
          } as SearchRequest);
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async sendReceipt(
        id: string,
        params: ReceiptRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.get(
            stringUtil.replace(endpoints.orders.send.receipt, {
              ':id': id,
            }),
            params
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async sendSms(
        id: string,
        request: MessageCustomerRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.get(
            stringUtil.replace(endpoints.orders.send.sms, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async trackPrint(id: string): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.put(
            stringUtil.replace(endpoints.orders.track.print, {
              ':id': id,
            })
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async updateCustomer(
        customer: BasicCustomer
      ): Promise<CheckoutCustomerResponse> {
        try {
          const data: CheckoutCustomerResponse = await api.post(
            stringUtil.replace(endpoints.customers.base, { ':id': '' }),
            {
              ...customer,
              orderId: storageUtil.local.get(config.storageKeys.guestOrder),
            }
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async updatePickupTime(
        request: UpdatePickupRequest
      ): Promise<CartResponse> {
        try {
          const data: CartResponse = await api.put(endpoints.orders.pickup, [
            request,
          ]);
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async voidInvoice(
        id: string,
        request: OrderCancelRequest
      ): Promise<InvoiceResponse> {
        try {
          console.log('voiding', { id });
          const data: InvoiceResponse = await api.post(
            stringUtil.replace(endpoints.invoices.voidInvoice, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async voidQuote(
        id: string,
        request: OrderCancelRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            stringUtil.replace(endpoints.invoices.quote.cancel, {
              ':id': id,
            }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async createInvoice(
        request: CreateInvoiceRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            endpoints.invoices.create,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async createQuote(request: CreateInvoiceRequest): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.post(
            endpoints.invoices.quote.create,
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async editInvoice(
        id: string,
        request: CreateInvoiceRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.patch(
            stringUtil.replace(endpoints.invoices.edit, { ':id': id }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async editQuote(
        id: string,
        request: CreateInvoiceRequest
      ): Promise<OrderResponse> {
        try {
          const data: OrderResponse = await api.patch(
            stringUtil.replace(endpoints.invoices.quote.edit, { ':id': id }),
            request
          );
          return data;
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async getCustomer(id: string) {
        try {
          const data: CustomerSuggestion[] = await api.get(
            stringUtil.replace(endpoints.customers.base, { ':id': id })
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },

      async customersAutoComplete(request: AutocompleteRequest) {
        try {
          const data: CustomerSuggestion[] = await api.get(
            endpoints.customers.autocompleteVendors,
            request
          );
          return { success: true, results: data };
        } catch (error: unknown) {
          return Promise.reject(error);
        }
      },
    };
  }, [api]);
};
