import React, { useMemo, useState } from 'react';
import { Button, Icon, Loading, Text } from '@goodfynd/react-web.lib.ui';
import type { IconName } from '@goodfynd/react-web.ui.icon';
import { Menu } from '@mui/material';
import truncate from 'lodash/truncate';

import { StyledMenu, StyledMenuItem } from './styles';
import type { FilterMenuAutocompleteProps } from './types';
export default function FilterMenuAutocomplete({
  busy,
  onChange,
  options,
  showCounts = true,
  value,
  ...props
}: Omit<FilterMenuAutocompleteProps, 'placeholder'>) {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClose = () => setAnchor(null);
  const handleMenuClick = (e: ClickButtonEvent) => {
    setAnchor(e.currentTarget);
  };

  const selectedLabel = useMemo(
    () =>
      Array.isArray(value)
        ? value.map((option) => option.label).join(', ')
        : value?.label,
    [value]
  );
  const selectedValues = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((option) => option.value);
    }

    return value ? [value.value] : [];
  }, [value]);

  return (
    <>
      <Button
        aria-controls="search-filter-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        iconRight={open ? 'chevron_up' : 'chevron_down'}
        id="search-filter-toggle"
        onClick={handleMenuClick}
        size="small"
        variant="filter-chip"
        {...props}
      >
        {busy ? (
          <Loading type="line" />
        ) : (
          <Text as="body2">
            {selectedLabel
              ? `${props.label}: ${truncate(selectedLabel, { length: 15 })}`
              : props.label}
          </Text>
        )}
      </Button>

      <Menu
        anchorEl={anchor}
        elevation={0}
        id="search-filter-menu"
        onClose={handleClose}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'search-filter-toggle',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <StyledMenu>
          {options.map((option) => {
            const icon: IconName | undefined = option.isDeleted
              ? 'hide'
              : option.icon;
            return (
              <StyledMenuItem
                key={option.value}
                onClick={() => onChange(option)}
              >
                <span>
                  <span style={{ display: 'flex' }}>
                    {!!icon && <Icon name={icon} size={16} />}
                    {option.label}
                  </span>
                  <Text as="body2">{option.description}</Text>
                </span>

                {selectedValues.includes(option.value) ? (
                  <Icon name="check" />
                ) : (
                  Number(option.count) > 0 &&
                  showCounts && <Text as="caption">{option.count}</Text>
                )}
              </StyledMenuItem>
            );
          })}
        </StyledMenu>
      </Menu>
    </>
  );
}
