import React from 'react';
import Fade from '@mui/material/Fade';
import MuiModal from '@mui/material/Modal';

import {
  StyledBody,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledIconButton,
} from './styles';
import type { ModalProps } from './types';

export default function Modal({
  children,
  close,
  closeButtonCss,
  containerCss,
  contentCss,
  footer,
  header,
  headerCss,
  showClose = true,
  ...props
}: ModalProps) {
  const handleClose = () => {
    close();
  };

  return (
    <MuiModal closeAfterTransition onClose={handleClose} {...props}>
      <section>
        <Fade in={props.open}>
          <StyledContainer css={containerCss}>
            <StyledBody>
              {header && <StyledHeader css={headerCss}>{header}</StyledHeader>}

              {header && showClose && (
                <StyledIconButton
                  aria-hidden={!props.open}
                  aria-label="Close Modal"
                  css={closeButtonCss}
                  icon="close"
                  onClick={handleClose}
                />
              )}

              <StyledContent css={contentCss}>
                {!header && showClose && (
                  <StyledIconButton
                    css={closeButtonCss}
                    icon="close"
                    onClick={handleClose}
                  />
                )}

                {children}
              </StyledContent>

              {footer && <StyledFooter>{footer}</StyledFooter>}
            </StyledBody>
          </StyledContainer>
        </Fade>
      </section>
    </MuiModal>
  );
}
