import { createContext, useState } from 'react';

import {
  ProfileContextValue,
  ProfileDispatchContextValue,
  ProfileProviderProps,
} from './types';

const initialProfileContextValue = {
  description: '',
  icon: '',
  id: '',
  label: '',
  nameId: '',
  type: '',
  value: '',
  status: '',
};

export const ProfileContext = createContext<ProfileContextValue>(
  initialProfileContextValue
);

export const ProfileDispatchContext = createContext<
  ProfileDispatchContextValue | undefined
>(undefined);

const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const [profile, setProfile] = useState<ProfileContextValue>(
    initialProfileContextValue
  );

  return (
    <ProfileContext.Provider
      value={{
        description: profile.description,
        icon: profile.icon,
        id: profile.id,
        label: profile.label,
        nameId: profile.nameId,
        type: profile.type,
        value: profile.value,
        status: profile.status,
      }}
    >
      <ProfileDispatchContext.Provider value={{ setProfile }}>
        {children}
      </ProfileDispatchContext.Provider>
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;
