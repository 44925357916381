import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { useToggle } from '@goodfynd/react-web.lib.hooks';

type SidebarContextValue = {
  isCollapsed: boolean;
  toggleSidebar: (value: unknown) => void;
};
const SidebarContext = createContext<SidebarContextValue | undefined>(
  undefined
);

export const SidebarProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isCollapsed, toggleSidebar] = useToggle(false);
  return (
    <SidebarContext.Provider value={{ isCollapsed, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebar() {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('The useSidebar hook must be used within a Sidebar.');
  }
  return context;
}
