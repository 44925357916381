import { useQuery } from 'react-query';

import config from '../config';
import { useVendorApi } from './api';
import { VendorTaxResponse } from '../services/api/types';
import { VendorTax } from '../types/vendors';

type Options = {
  vendorId?: string;
};
export default function useVendorTaxes({ vendorId }: Options = {}) {
  const api = useVendorApi();
  const { data, isFetched, isFetching, isLoading, refetch } = useQuery<
    VendorTaxResponse,
    any
  >([config.queryKeys.vendorTaxes, vendorId], () => api.getTaxes(vendorId!), {
    enabled: !!vendorId,
  });

  return {
    isFetched,
    isFetching,
    isLoading,
    refetch,
    taxes: data?.results ?? ([] as VendorTax[]),
  };
}
