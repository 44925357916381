import React from 'react';
import strings from '@config/strings';
import { GeoInput } from '@goodfynd/react-web.lib.forms';
import Text from '@goodfynd/react-web.typography.text';

import { ModalForm } from '../forms';

import type { FindLocation, ModalFindLocationProps } from './types';
export default function ModalFindLocation({
  contentTop,
  helperText,
  title,
  ...props
}: ModalFindLocationProps) {
  return (
    <ModalForm<FindLocation>
      {...props}
      submitButtonText={strings.labels.updateLocation}
      header={
        title && (
          <Text as="h2" type="h5">
            {title}
          </Text>
        )
      }
      renderFields={({ control, setValue }) => (
        <>
          {contentTop}

          <GeoInput<FindLocation>
            autoFocus
            helperText={helperText}
            label="Location"
            lookup
            name="location"
            showAddressFields
            showMap
            {...{ control, setValue }}
          />
        </>
      )}
    />
  );
}
