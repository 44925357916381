import React, { useCallback } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { StyledContainer, StyledFormControl } from '../styles';
import type { InputRender, InputSelectProps } from '../types';

export default function InputSelect<T extends FieldValues>({
  name,
  options,
  control,
  css,
  inputProps,
  label,
  defaultValue,
  addEmptyOption,
  id,
}: InputSelectProps<T>) {
  const renderOptions = useCallback(() => {
    return options.map((option: InputOption, index) => {
      return (
        <MenuItem
          key={typeof option.label === 'string' ? option.label : index}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      );
    });
  }, [options]);

  const renderSelect = useCallback(
    ({ field: { onChange, value }, fieldState: { error } }: InputRender<T>) => (
      <>
        <Select
          id={id}
          {...inputProps}
          label={label}
          onChange={onChange}
          value={value}
        >
          {addEmptyOption && (
            <MenuItem key="none" value="">
              None
            </MenuItem>
          )}
          {renderOptions()}
        </Select>
        <FormHelperText error={!!error}>{error?.message}</FormHelperText>
      </>
    ),
    [addEmptyOption, id, inputProps, label, renderOptions]
  );

  return (
    <StyledContainer css={css}>
      <StyledFormControl>
        <InputLabel>{label}</InputLabel>
        <Controller
          control={control}
          defaultValue={defaultValue}
          name={name}
          render={renderSelect}
        />
      </StyledFormControl>
    </StyledContainer>
  );
}
