import React, { useCallback, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from '@goodfynd/react-web.context.snackbar-context';
import { strings } from '@goodfynd/react-web.lib.strings';
import { IconButton } from '@goodfynd/react-web.lib.ui';
import type { IconName } from '@goodfynd/react-web.ui.icon';

import { useWebShare } from '../../hooks';

import { StyledButton } from './styles';
import type { ShareButtonProps } from './types';

export default function ShareButton({
  buttonStyle = 'default',
  text,
  title,
  url = window.location.href,
  ...props
}: ShareButtonProps) {
  const { openSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false);
  const { busy, isSupported, share } = useWebShare();

  const handleClick = useCallback(
    async (e: ClickButtonEvent) => {
      if (!isSupported) {
        return e.preventDefault();
      }

      share({ text, title, url });
    },
    [isSupported, share, text, title, url]
  );

  const handleCopy = useCallback(() => {
    openSnackbar({
      message: strings.labels.copiedToClipboard,
      type: 'success',
    });

    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 1000);
  }, [openSnackbar]);

  const commonProps = useMemo(
    () => ({
      disabled: busy,
      onClick: handleClick,
      ...props,
    }),
    [busy, handleClick, props]
  );

  const renderButton = useCallback(() => {
    const iconName: IconName = /iPhone|iPad|iPod/i.test(navigator.userAgent)
      ? 'share_ios'
      : 'share_android';

    switch (buttonStyle) {
      case 'default':
        return (
          <StyledButton
            busy={busy}
            color="neutral"
            iconLeft={success ? 'check' : iconName}
            size="small"
            {...commonProps}
          >
            {strings.labels.share}
          </StyledButton>
        );

      case 'icon':
        return (
          <IconButton
            busy={busy}
            color="neutral"
            icon={success ? 'check' : iconName}
            {...commonProps}
          />
        );
    }
  }, [busy, buttonStyle, commonProps, success]);

  return isSupported ? (
    renderButton()
  ) : (
    <CopyToClipboard onCopy={handleCopy} text={url}>
      {renderButton()}
    </CopyToClipboard>
  );
}
