import Trend from 'react-trend';
import Text from '@goodfynd/react-web.typography.text';

import { styled } from '../../theme/stitches.config';

export const StyledAxisLabel = styled(Text, {
  fontSizeRem: 10,
  lineHeight: 18 / 10,
});

export const StyledContainer = styled('div', {
  width: '100%',
});

export const StyledTrend = styled(Trend, {
  position: 'relative',
  top: 8,
});
