import React, { useMemo } from 'react';
import { useApp } from '@context/AppContext';
import { PageTitleBanner } from '@goodfynd/react-web.layout.page-title-banner';
import { LoadingTruck } from '@goodfynd/react-web.lib.ui';
import { useRequireAuth } from '@hooks/useRequireAuth';
import { useVendor } from 'context/VendorContext';

import Footer from '../Footer';
import Header from '../Header';
import { LoadingFullPage } from '../LoadingFullPage';

import { StyledPageContainer } from './styles';
import type { LayoutWithHeaderProps } from './types';

export function LayoutWithVendorHeader({
  bannerContainerCss,
  children,
  allowedRoles,
  ...props
}: Omit<LayoutWithHeaderProps, 'authRequired'>): JSX.Element {
  const isLoggedIn = useRequireAuth({ allowedRoles });
  const { userIsLoading } = useApp();
  const { isLoading: vendorIsLoading, vendor } = useVendor();
  const isLoading = useMemo(
    () => userIsLoading || vendorIsLoading,
    [userIsLoading, vendorIsLoading]
  );

  if (!isLoggedIn) {
    return <></>;
  }

  return (
    <StyledPageContainer css={{ paddingTop: 64 }}>
      <Header />
      <>
        {typeof props.bannerTitle === 'string' ? (
          <PageTitleBanner
            containerCss={bannerContainerCss}
            subtitle={vendor?.uniqueName ?? props.bannerSubTitle}
            title={props.bannerTitle}
          />
        ) : (
          props.bannerTitle
        )}

        {isLoading ? (
          <LoadingFullPage>
            <LoadingTruck />
          </LoadingFullPage>
        ) : (
          children
        )}
      </>
      <Footer />
    </StyledPageContainer>
  );
}
