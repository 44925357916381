import { routes as routesV2 } from './routes-v2';

export const routes = {
  home: '/',
  beta: routesV2,
  blog: 'https://blog.goodfynd.com',
  careers: 'https://www.goodfynd.com/careers',
  dashboard: {
    add: '/add',
    edit: '/dashboard/:id/edit',
    events: {
      home: '/dashboard/:id/events',
      awaitingConfirmation: '/dashboard/:id/events/awaiting-confirmation',
      confirmed: '/dashboard/:id/events/confirmed',
      interest: '/dashboard/:id/events/interest',
      rejected: '/dashboard/:id/events/rejected',
    },
    home: '/dashboard',
    info: {
      about: '/dashboard/:id/info',
      licenses: '/dashboard/:id/info/licenses',
      payouts: '/dashboard/:id/info/payouts',
      personnel: '/dashboard/:id/info/personnel',
    },
    invoices: {
      home: '/dashboard/:id/invoices',
      add: '/dashboard/:id/invoices/add',
      all: '/dashboard/:id/invoices/all',
      edit: '/dashboard/:id/invoices/edit/:invoiceId',
      outstanding: '/dashboard/:id/invoices/outstanding',
      paid: '/dashboard/:id/invoices/paid',
      quotes: '/dashboard/:id/invoices/quotes',
      refunded: '/dashboard/:id/invoices/refunded',
      voided: '/dashboard/:id/invoices/voided',
    },
    quotes: {
      add: '/dashboard/:id/quotes/add',
      edit: '/dashboard/:id/quotes/edit/:quoteId',
    },
    orders: {
      home: '/dashboard/:id/orders',
      all: '/dashboard/:id/orders/all',
      future: '/dashboard/:id/orders/future',
      next30Mins: '/dashboard/:id/orders/next30min',
      today: '/dashboard/:id/orders/today',
      tomorrow: '/dashboard/:id/orders/tomorrow',
      walkups: '/dashboard/:id/orders/walk-ups',
    },
    overview: '/dashboard/:id/overview',
    products: {
      home: '/dashboard/:id/products',
      addItem: '/dashboard/:id/products/add',
      editItem: '/dashboard/:id/products/edit/:itemId',
      items: '/dashboard/:id/products/items',
      itemOptions: '/dashboard/:id/products/options',
      categories: '/dashboard/:id/products/categories',
      menus: '/dashboard/:id/products/menus',
      globalItems: '/dashboard/:id/products/items/global',
    },
    promos: {
      home: '/dashboard/:id/promos',
    },
    reports: {
      home: '/dashboard/:id/reports',
      invoices: '/dashboard/:id/reports/invoices',
      items: '/dashboard/:id/reports/items',
      payouts: '/dashboard/:id/reports/payouts',
      allpayouts: '/dashboard/:id/reports/payouts',
      summaries: '/dashboard/:id/reports/summaries',
      tickets: '/dashboard/:id/reports/transactions',
    },
    schedule: {
      flyers: '/dashboard/:id/schedule/flyers',
      googleSync: '/dashboard/:id/schedule/google-sync',
      home: '/dashboard/:id/schedule',
      map: '/dashboard/:id/schedule/map',
      update: '/dashboard/:id/schedule/update',
    },
    settings: {
      home: '/dashboard/:id/settings',
      ordering: '/dashboard/:id/settings',
      orderingSettingsWizard: (vendorNameId: string) =>
        `/dashboard/ordering-settings-wizard?vendor_id=${vendorNameId}`,
      orderingSettingsWizardPath: '/dashboard/ordering-settings-wizard',
    },
  },
  docs: '/docs',
  unauthorized: '/401',
  accessDenied: '/403',
  events: {
    details: '/events/details/:id',
  },
  maintenance: '/maintenance',
  notFound: '/404',
  support: {
    home: '/dashboard/:id/support',
  },
  account: {
    callback: '/auth/callback',
    home: 'https://www.goodfynd.com/profile',
    signIn: '/auth/login',
    logout: '/auth/logout',
    profile: '/auth/profile',
    signUp: '/auth/signup',
    unverified: '/auth/unverified',
    orders: 'https://order.goodfynd.com/profile/order-history',
  },
  apps: {
    android:
      'https://play.google.com/store/apps/details?id=com.goodfynd.trucks',
    ios: 'https://apps.apple.com/us/app/goodfynd/id1402316922?ls=1',
  },
  help: {
    home: '/help',
    chat: '/chat',
  },
  legal: {
    fulfillment: 'https://www.goodfynd.com/legal/fulfillment',
    privacy: 'https://www.goodfynd.com/legal/privacy',
    terms: 'https://www.goodfynd.com/legal/terms',
  },
  organization: {
    add: '/organization/:organizationId/add',
    edit: '/organization/:organizationId/edit',
    employee: {
      add: '/organization/:organizationId/employee/add',
      edit: '/organization/:organizationId/employee/edit/:id',
    },
    events: '/organization/:organizationId/events',
    info: {
      home: '/organization/:organizationId/info',
      addOns: '/organization/:organizationId/info/add-ons',
      hardwareServices: '/organization/:organizationId/info/hardware-services',
      licenses: '/organization/:organizationId/info/licenses',
      payments: '/organization/:organizationId/info/payments',
      payouts: '/organization/:organizationId/info/payouts',
      personnel: '/organization/:organizationId/info/personnel',
      structure: '/organization/:organizationId/info/structure',
      territories: '/organization/:organizationId/info/territories',
    },
    invoices: {
      home: '/organization/:organizationId/invoices',
      add: '/organization/:organizationId/invoices/add',
      all: '/organization/:organizationId/invoices/all',
      edit: '/organization/:organizationId/invoices/edit/:quoteId',
      outstanding: '/organization/:organizationId/invoices/outstanding',
      paid: '/organization/:organizationId/invoices/paid',
      quotes: '/organization/:organizationId/invoices/quotes',
      refunded: '/organization/:organizationId/invoices/refunded',
      voided: '/organization/:organizationId/invoices/voided',
    },
    orderingSettingsWizardPath:
      '/organization/:organizationId/settings/ordering',
    orders: {
      home: '/organization/:organizationId/orders',
      all: '/organization/:organizationId/orders/all',
      future: '/organization/:organizationId/orders/future',
      next30Mins: '/organization/:organizationId/orders/next30min',
      today: '/organization/:organizationId/orders/today',
      tomorrow: '/organization/:organizationId/orders/tomorrow',
      walkups: '/organization/:organizationId/orders/walk-ups',
    },
    overview: '/organization/:organizationId/overview',
    products: {
      items: '/organization/:organizationId/products/items',
      options: '/organization/:organizationId/products/options',
      categories: '/organization/:organizationId/products/categories',
      menu: '/organization/:organizationId/products/menus',
      addItem: '/organization/:organizationId/products/add',
      editItem: '/organization/:organizationId/products/edit/:id',
    },
    promos: '/organization/:organizationId/promos',
    radar: '/organization/:organizationId/radar',
    reports: {
      home: '/organization/:organizationId/reports',
      invoices: '/organization/:organizationId/reports/invoices',
      items: '/organization/:organizationId/reports/items',
      payouts: '/organization/:organizationId/reports/payouts',
      summaries: '/organization/:organizationId/reports/summaries',
      transactions: '/organization/:organizationId/reports/transactions',
    },
    schedule: {
      home: '/organization/:organizationId/schedule',
      map: '/organization/:organizationId/schedule/map',
    },
    settings: '/organization/:organizationId/settings',
    support: {
      home: '/organization/:organizationId/support',
    },
  },
  search: {
    products: 'https://www.goodfynd.com/search/products',
    vendors: 'https://www.goodfynd.com/search/vendors',
  },
  social: {
    facebook: 'https://www.facebook.com/goodfynd',
    instagram: 'https://www.instagram.com/goodfynd/',
    linkedIn: 'https://www.linkedin.com/company/goodfynd',
    twitter: 'https://twitter.com/goodfynd',
  },
  stripe: {
    onboarding: '/stripe/onboarding?organizationId=:organizationId',
  },
};

export const driftRoutes = ['/docs/*', routes.home, '/events/details/*'];

export const externalRoutes = {
  events: {
    consumerLanding: 'https://www.goodfynd.com/events',
  },
  vendors: {
    details: 'https://www.goodfynd.com/vendors/:id',
  },
};

export default routes;
